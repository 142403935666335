import Api from "@/api"

const ADDRESS = "/maps/address/"
const KM = "/maps/kms/"

export default {
    getAddress(location) {
        return Api.http(true).post(ADDRESS, {
            location,
        })
    },
    getKm(origin, destination) {
        return Api.http(true).post(KM, {
            origin,
            destination,
        })
    },
}
