<template>
    <b-row>
        <b-col
            v-if="allKms.length >= 1 && kmDetail === true"
            cols="12"
            xl="12"
            lg="12"
            md="12"
            class="d-flex mb-1"
            style="cursor: pointer"
            @click="
                kmDetail = false
                kms = {}
            "
        >
            <feather-icon
                style="margin-top: 3px; padding-left: 0px; margin-left: 0px"
                icon="ArrowLeftIcon"
                size="1x"
            />
            <div style="margin-left: 5px">Back</div>
        </b-col>
        <b-col cols="12" xl="9" lg="12" md="12">
            <info-km
                v-if="kms.status && up"
                ref="infokms"
                :user-data="kms"
                :hasBalance="hasBalance"
                v-model="balanceCalculated"
            />
        </b-col>
        <b-col cols="12" md="12" xl="3" lg="12">
            <last-km
                v-if="entries.length > 0 && kms.total_kms > 0 && this.up"
                v-model="kms"
            />
        </b-col>
        <b-col>
            <b-card v-if="kms.id">
                <div class="d-flex">
                    <b-button
                        v-if="
                            prevActive &&
                            $store.state.portal.profile.employee.mileage &&
                            (this.$route.path === '/forms/mileage/' ||
                                this.$route.path === '/forms/mileage')
                        "
                        v-ripple.400="'rgba(30, 30, 30, 0)'"
                        variant="flat-secondary"
                        class="btn-icon rounded-circle mb-1"
                        @click="previousMonth()"
                    >
                        <feather-icon icon="ChevronLeftIcon" size="1.5x" />
                    </b-button>
                    <b-button
                        v-else-if="
                            $store.state.portal.profile.employee.mileage &&
                            (this.$route.path === '/forms/mileage/' ||
                                this.$route.path === '/forms/mileage')
                        "
                        v-ripple.400="'rgba(30, 30, 30, 0)'"
                        variant="flat-secondary"
                        class="btn-icon rounded-circle mb-1"
                        disabled
                        @click="previousMonth()"
                    >
                        <feather-icon icon="ChevronLeftIcon" size="1.5x" />
                    </b-button>
                    <b-button
                        v-if="
                            nextActive &&
                            $store.state.portal.profile.employee.mileage &&
                            (this.$route.path === '/forms/mileage/' ||
                                this.$route.path === '/forms/mileage')
                        "
                        v-ripple.400="'rgba(30, 30, 30, 0)'"
                        variant="flat-secondary"
                        class="btn-icon rounded-circle mb-1"
                        @click="nextMonth()"
                    >
                        <feather-icon icon="ChevronRightIcon" size="1.5x" />
                    </b-button>
                    <b-button
                        v-else-if="
                            $store.state.portal.profile.employee.mileage &&
                            (this.$route.path === '/forms/mileage/' ||
                                this.$route.path === '/forms/mileage')
                        "
                        v-ripple.400="'rgba(30, 30, 30, 0)'"
                        variant="flat-secondary"
                        class="btn-icon rounded-circle mb-1"
                        disabled
                        @click="nextMonth()"
                    >
                        <feather-icon icon="ChevronRightIcon" size="1.5x" />
                    </b-button>
                    <div
                        v-if="
                            $store.state.portal.profile.employee.mileage &&
                            (this.$route.path === '/forms/mileage/' ||
                                this.$route.path === '/forms/mileage')
                        "
                    >
                        <b-button
                            id="monthDate"
                            v-ripple.400="'rgba(30, 30, 30, 0)'"
                            style="margin-top: -5px"
                            size="lg"
                            variant="flat-secondary"
                            class="btn-icon mb-1 ml-10"
                            @click="
                                monthPicker =
                                    monthPicker === true ? false : true
                            "
                        >
                            {{ kms.date | monthYearFormat }}
                            <feather-icon icon="ChevronDownIcon" size="1x" />
                        </b-button>
                        <div
                            v-if="monthPicker && kms.date"
                            style="position: absolute"
                            class="mp-index"
                        >
                            <Calendar
                                v-if="monthPicker && kms.date"
                                v-model="month"
                                view="month"
                                date-format="mm/yy"
                                :inline="true"
                                :min-date="
                                    new Date(
                                        new Date(kms.months).setMonth(
                                            new Date(kms.months).getMonth()
                                        )
                                    )
                                "
                                :max-date="
                                    new Date(
                                        new Date().setMonth(
                                            new Date().getMonth() + 1
                                        )
                                    )
                                "
                            />
                            <!-- Form Actions -->
                            <div
                                class="d-flex custom-month-picker"
                                style="flex-direction: row-reverse"
                            >
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    class="margin-left-10"
                                    variant="primary"
                                    size="sm"
                                    @click="showDate(month)"
                                >
                                    Apply
                                </b-button>
                                <b-button
                                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                    variant="outline-secondary"
                                    size="sm"
                                    @click="
                                        monthPicker =
                                            monthPicker === true ? false : true
                                    "
                                >
                                    Cancel
                                </b-button>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="
                            !$store.state.portal.profile.employee.mileage ||
                            (this.$route.path !== '/forms/mileage/' &&
                                this.$route.path !== '/forms/mileage')
                        "
                        class="ml-1 mt-10 fs-16 mb-1"
                    >
                        <span
                            style="
                                font-size: 1.25rem;
                                font-weight: 500;
                                color: #82868b;
                            "
                            >{{ kms.date | monthYearFormat }}</span
                        >
                    </div>
                    <div class="mr-auto mt-10">
                        <b-badge
                            v-if="
                                kms.status &&
                                Object.keys(kms.status).length !== 0
                            "
                            class="ml-1"
                            :variant="'light-' + setStatusColor()"
                        >
                            {{ kms.status.description }}
                        </b-badge>
                    </div>
                    <div>
                        <!-- <b-button
              v-if="$route.path !== '/forms/mileage/' && $route.path !== '/forms/mileage' && kms.status && (kms.status.description === 'Pending approval' || kms.status.description === 'Pending changes')"
              v-ripple.400="'rgba(24,	91,	137, 0.15)'"
              variant="outline-primary"
              class="mr-1"
              @click="openRequest()"
            >
              Request changes
            </b-button> -->
                        <b-dropdown
                            v-if="
                                $route.path !== '/forms/mileage' &&
                                $route.path !== '/forms/mileage/'
                            "
                            id="dropdown-7"
                            v-ripple.400="'rgba(255, 255, 255, 0)'"
                            class="mr-1"
                            text="Actions"
                            variant="secondary"
                        >
                            <b-dropdown-item>Select action:</b-dropdown-item>
                            <b-dropdown-item
                                :disabled="!approve"
                                @click="save(3)"
                                ><feather-icon
                                    class="text-success mr-right-10"
                                    icon="CheckIcon"
                                />Approve</b-dropdown-item
                            >
                            <b-dropdown-item
                                :disabled="!decline"
                                @click="save(4)"
                                ><feather-icon
                                    class="text-danger mr-right-10"
                                    icon="XIcon"
                                />Decline</b-dropdown-item
                            >
                            <b-dropdown-item
                                :disabled="!nudge"
                                @click="nudgeAction()"
                                ><feather-icon
                                    class="text-warning mr-right-10"
                                    icon="SendIcon"
                                />Nudge</b-dropdown-item
                            >
                            <b-dropdown-item
                                :disabled="!reopen"
                                @click="save(1)"
                                ><feather-icon
                                    class="text-info mr-right-10"
                                    icon="RotateCwIcon"
                                />Reopen</b-dropdown-item
                            >
                            <b-dropdown-item
                                v-b-modal.change-request
                                :disabled="!changeRequest"
                                ><feather-icon
                                    class="text-secondary mr-right-10"
                                    icon="Edit3Icon"
                                />Change request</b-dropdown-item
                            >
                        </b-dropdown>
                        <b-dropdown
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="flat-secondary"
                            text="Export"
                            :disabled="
                                kms.status.description === 'Closed' &&
                                kms.is_empty === true
                            "
                        >
                            <template #button-content>
                                <feather-icon icon="DownloadIcon" size="1.5x" />
                            </template>
                            <b-dropdown-item @click="download()">
                                Excel
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="download((exportTo = 'pdf'))"
                            >
                                PDF
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <div
                        v-if="
                            kms.date.split('-')[1] > 1 &&
                            entries[0] !== undefined &&
                            ['Open', 'Pending changes'].includes(
                                kms.status.description
                            )
                        "
                    >
                        <b-button
                            v-b-tooltip.hover.right="
                                'Import past mileage'
                            "
                            size="md"
                            v-ripple.400="'rgba(30, 30, 30, 0)'"
                            variant="flat-secondary"
                            class="btn-icon mb-1 ml-10"
                            @click="importLastMonth()"
                        >
                            <feather-icon size="1.5x" icon="CopyIcon" />
                        </b-button>
                    </div>
                </div>
                <div>
                    <app-collapse
                        v-if="request"
                        class="shadow mb-1 bg-white rounded"
                        style="
                            padding-bottom: 10px;
                            z-index: 1;
                            overflow: scroll;
                        "
                    >
                        <app-collapse-item
                            ref="tlMessages"
                            :is-visible="true"
                            :title="
                                isVisible
                                    ? ' ' + 'Hide change request'
                                    : ' ' + 'View change request'
                            "
                            style="z-index: 1"
                            @visible="isVisible = $event"
                        >
                            <app-timeline style="z-index: 1">
                                <app-timeline-item
                                    v-for="item in messages"
                                    :key="item.id"
                                    :title="
                                        item.created_by.first_name +
                                        ' ' +
                                        item.created_by.last_name +
                                        ':'
                                    "
                                    :subtitle="item.content"
                                    :time="item.created_at"
                                    variant="primary"
                                    class="mt-1"
                                    style="z-index: 1"
                                />
                            </app-timeline>
                        </app-collapse-item>
                    </app-collapse>
                </div>
                <km-table
                    v-if="
                        entries.length > 0 &&
                        dateMaxMin.minDate !== '' &&
                        rerenderKmTable
                    "
                    ref="refKmTable"
                    v-model="entries"
                    :date-max-min="dateMaxMin"
                    :action="action"
                    :kms="kms"
                    :department="department"
                    :hasBalance="hasBalance"
                    :submited="submited"
                    @delete-field="deleteField"
                    @real-value="calculateAmoutEntries($event)"
                    @changeKm="upgradeKm()"
                    @change="verifyOverlap($event)"
                />
                <b-modal
                    id="model-signature"
                    centered
                    title="Signature"
                    ok-only
                    ok-title="Save"
                    size="lg"
                    @ok="signatureSave"
                >
                    <b-card-text>
                        <h5>
                            Sign your mileage form before submitting it for
                            approval. Please select one of these options:
                        </h5>
                        <b-tabs
                            v-model="tabIndex"
                            content-class="mt-2"
                            class="mt-1"
                            justified
                        >
                            <b-tab
                                active
                                title="Create"
                                style="min-width: 100%"
                            >
                                <b-card-text>
                                    <h4>Create digital signature</h4>

                                    <p>
                                        When creating a digital signature your
                                        mileage forms will be automatically
                                        signed monthly.
                                    </p>

                                    <p>
                                        It's easy, fast and eliminates paper
                                        waste!
                                    </p>

                                    Use your mouse to draw the signature. Click
                                    “Save” when you make a perfect one.
                                </b-card-text>
                                <vueSignature
                                    ref="signature"
                                    :sig-option="option"
                                    w="760px"
                                    h="300px"
                                    style="border: 1px solid black"
                                    :disabled="disabled"
                                />
                                <b-button
                                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                    class="mt-1"
                                    style="float: right"
                                    variant="flat-secondary"
                                    @click="clear"
                                >
                                    <feather-icon
                                        icon="RotateCwIcon"
                                        class="mr-right-10"
                                    />
                                    Clear
                                </b-button>
                            </b-tab>
                            <b-tab title="Upload">
                                <b-card-text>
                                    <h4>Upload a signature</h4>

                                    <p>
                                        If you already have an image of your
                                        signature, you can also upload it and
                                        use it.
                                    </p>

                                    <p>
                                        You no longer have to worry about
                                        signing mileage form every time you
                                        submit it for approval.
                                    </p>

                                    <p>Once and never again!</p>
                                </b-card-text>
                                <b-form-file
                                    v-if="kms.employee"
                                    id="input-upload-signature"
                                    v-model="upload_signature"
                                    class="mt-1"
                                    placeholder="Choose a file or drop it here..."
                                    drop-placeholder="Drop file here..."
                                />
                                <p
                                    v-if="
                                        $store.state.portal.profile.employee !==
                                            'undefined' &&
                                        $store.state.portal.profile.employee
                                            .upload_signature
                                    "
                                    style="font-size: 10px"
                                >
                                    Selected file:
                                    <strong
                                        ><a
                                            :href="
                                                $store.state.portal.profile
                                                    .employee.upload_signature
                                                    ? $store.state.portal
                                                          .profile.employee
                                                          .upload_signature
                                                    : ''
                                            "
                                            target="_blank"
                                            >My Signature</a
                                        ></strong
                                    >
                                </p>
                            </b-tab>
                            <b-tab title="Manual">
                                <b-card-text>
                                    <h4>Manual</h4>

                                    <p>
                                        You can also sign mileage form manually.
                                    </p>

                                    <p>
                                        To do this, make sure you have completed
                                        all missing entries, download your
                                        mileage file, sign it, scan it and
                                        finally send it back by clicking “Upload
                                        it”.
                                    </p>
                                </b-card-text>
                                <div style="text-align: center">
                                    <b-button
                                        v-ripple.400="'rgba(24,	91,	137, 0.15)'"
                                        variant="outline-primary"
                                        @click="download((exportTo = 'pdf'))"
                                    >
                                        <feather-icon
                                            icon="DownloadIcon"
                                            class="mr-50"
                                        />
                                        <span class="align-middle"
                                            >Download</span
                                        >
                                    </b-button>
                                </div>
                                <b-card-text class="mt-1">
                                    <p>Already signed? Upload it below:</p>
                                </b-card-text>
                                <b-form-file
                                    v-model="file"
                                    placeholder="Choose a file or drop it here..."
                                    drop-placeholder="Drop file here..."
                                />
                                <p style="font-size: 10px">
                                    Selected file:
                                    <strong>{{ file ? file.name : "" }}</strong>
                                </p>
                                <b-card-text class="text-warning mt-1">
                                    Keep in mind that by choosing this option,
                                    you must to repeat this signing process
                                    every time you submit a mileage form.
                                </b-card-text>
                            </b-tab>
                        </b-tabs>
                    </b-card-text>
                </b-modal>
                <b-modal
                    id="change-request"
                    centered
                    title="Change request"
                    cancel
                    cancel-variant="outline-secondary"
                    ok-title="Submit request"
                    size="lg"
                    @ok="submitRequest()"
                >
                    <b-card-text>
                        Incorrect or missing information? Submit a change
                        request to the employee.
                    </b-card-text>
                    <b-card-text>
                        <b-form-group
                            class="mt-1"
                            label="Requested by"
                            label-for="requestedBy"
                        >
                            <b-form-input
                                id="requestedBy"
                                disabled
                                :value="
                                    $store.state.portal.profile.first_name +
                                    ' ' +
                                    $store.state.portal.profile.last_name
                                "
                            />
                        </b-form-group>
                    </b-card-text>
                    <b-card-text>
                        <label for="reason-for-change">Comments</label>
                        <b-form-textarea
                            id="reason-for-change"
                            v-model="message"
                            placeholder="Please use this space to explain reason for change, as well as additional information."
                            rows="3"
                        />
                    </b-card-text>
                </b-modal>
                <b-modal
                    id="modal-weekend"
                    centered
                    title="Confirm travel?"
                    cancel
                    cancel-variant="outline-secondary"
                    ok-title="Yes"
                    @cancel="resolveWeekendPromise(false)"
                    @ok="resolveWeekendPromise(true)"
                    @close="resolveWeekendPromise(false)"
                >
                    <b-card-text
                        v-if="kmWeekend.length > 1"
                        class="font-weight-bolder"
                    >
                        Are you sure you traveled on the following dates?
                    </b-card-text>
                    <b-card-text v-else class="font-weight-bolder">
                        Are you sure you traveled on the following date?
                    </b-card-text>
                    <b-card-text
                        v-for="item in kmWeekend"
                        :key="item.id"
                        class="text-primary"
                    >
                        {{ item.date }}, {{ item.day }}
                    </b-card-text>
                </b-modal>
                <b-card-text
                    v-if="
                        (kms.status.description === 'Open' ||
                            kms.status.description === 'Closed') &&
                        entries.length <= 1
                    "
                    class="text-primary ml-1 font-weight-bolder"
                >
                    If you have not taken trips this month, please select the
                    following option and then click on “Submit”:
                    <b-form-checkbox
                        v-model="kms.is_empty"
                        class="mt-10 mb-1"
                        :disabled="
                            kms.status.description === 'Closed' || kms.condition
                        "
                    >
                        I confirm that I have no mileage to declare for the
                        current cycle.
                    </b-form-checkbox>
                </b-card-text>
                <b-card-text
                    v-if="kms.is_empty === false"
                    class="ml-1 font-weight-bolder"
                    style="font-size: 12px; color: #5e5873"
                >
                    I accept<span class="text-danger">*</span>:
                    <b-form-checkbox
                        id="checkStatement"
                        v-model="kms.condition"
                        class="mt-10 mb-2"
                        :disabled="
                            kms.status.description !== 'Open' || kms.is_empty
                        "
                    >
                        <span style="font-size: 12px"
                            >Employee Portal uses your signature exclusively for
                            the purpose of signing mileage reports, timesheets
                            and expense reports, if applicable.</span
                        >
                    </b-form-checkbox>
                </b-card-text>
                <b-button
                    v-if="
                        kms.status &&
                        kms.is_empty === false &&
                        (kms.status.description === 'Open' ||
                            kms.status.description === 'Pending changes')
                    "
                    v-ripple.400="'rgba(0, 0,	0, 0)'"
                    variant="outline-primary"
                    @click="
                        add(
                            {
                                item: entries[0],
                            },
                            true
                        )
                    "
                >
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="align-middle">Add mileage</span>
                </b-button>
                <div style="float: right">
                    <div class="demo-inline-spacing">
                        <b-button
                            v-if="
                                kms.status &&
                                kms.is_empty === false &&
                                (kms.status.description === 'Open' ||
                                    kms.status.description ===
                                        'Pending changes')
                            "
                            v-ripple.400="'rgba(0, 0,	0, 0)'"
                            variant="flat-primary"
                            @click="saveKms()"
                        >
                            <feather-icon icon="SaveIcon" class="mr-50" />
                            <span class="align-middle">Save</span>
                        </b-button>
                        <b-button
                            v-if="
                                kms.status &&
                                (kms.status.description === 'Open' ||
                                    kms.status.description ===
                                        'Pending changes')
                            "
                            v-ripple.400="'rgba(0, 0,	0, 0)'"
                            variant="primary"
                            @click="submit()"
                        >
                            Submit
                        </b-button>
                        <b-button
                            v-if="
                                kms.status &&
                                (kms.status.description === 'Open' ||
                                    kms.status.description ===
                                        'Pending changes') &&
                                !$store.state.portal.profile.employee
                                    .signature &&
                                !$store.state.portal.profile.employee
                                    .upload_signature
                            "
                            v-ripple.400="'rgba(24,	91,	137, 0.15)'"
                            v-b-modal.model-signature
                            variant="outline-primary"
                        >
                            <feather-icon icon="Edit3Icon" class="mr-50" />
                            <span class="align-middle">Signature</span>
                        </b-button>
                    </div>
                </div>
            </b-card>
            <div v-else-if="notKms" />
            <b-card v-else>
                <div class="custom-search d-flex">
                    <div class="d-flex mr-auto">
                        <b-card-text style="font-size: 16px">
                            Click on date to check or record your mileage
                        </b-card-text>
                    </div>
                    <div class="d-flex align-items-center">
                        <b-form-group>
                            <b-form-input
                                v-model="searchTerm"
                                placeholder="Search"
                                type="text"
                                class="d-inline-block"
                            />
                        </b-form-group>
                    </div>
                </div>
                <vue-good-table
                    :columns="columns"
                    :rows="allKms"
                    :search-options="{
                        enabled: true,
                        externalQuery: searchTerm,
                    }"
                >
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field === 'status'">
                            <b-badge
                                :variant="
                                    statusVariant(props.row.status.description)
                                "
                            >
                                {{ props.row.status.description }}
                            </b-badge>
                        </span>
                        <span
                            v-else-if="props.column.field === 'employee.name'"
                            style="cursor: pointer"
                            @click="
                                $router.push(
                                    '/forms/mileage/' +
                                        props.row.employee.id +
                                        '/' +
                                        props.row.date.split('-')[1] +
                                        '/' +
                                        props.row.date.split('-')[0]
                                )
                            "
                        >
                            <b-avatar
                                class="mr-right-10"
                                size="36px"
                                :src="props.row.employee.avatar"
                            />
                            {{ props.row.employee.name }}
                        </span>
                        <span
                            v-else-if="
                                props.column.field ===
                                'employee.reports_to.name'
                            "
                            class="d-flex"
                        >
                            <b-avatar
                                v-if="props.row.employee.reports_to"
                                class="mr-right-10"
                                size="36px"
                                :src="props.row.employee.reports_to.avatar"
                            />
                            <div
                                v-if="props.row.employee.reports_to"
                                class="name-vt-align"
                            >
                                {{ props.row.employee.reports_to.name }}
                            </div>
                        </span>
                        <span
                            v-else-if="props.column.field === 'date'"
                            v-b-tooltip.hover.right="
                                'Click to check or record!'
                            "
                            style="cursor: pointer"
                            @click="
                                showDetail(
                                    props.formattedRow[props.column.field]
                                )
                            "
                        >
                            {{
                                props.formattedRow[props.column.field]
                                    | monthYearFormat
                            }}
                        </span>
                        <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </template>
                </vue-good-table>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BButton,
    VBTooltip,
    BBadge,
    BTabs,
    BTab,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import Ripple from "vue-ripple-directive"
import InfoKm from "@/components/InfoKm"
import LastKm from "@/components/LastKm"
import KmTable from "@/components/KmTable"
import Km from "@/api/services/Km"
import vueSignature from "vue-signature"
import Moment from "moment"
import { extendMoment } from "moment-range"
import Auth from "@/api/services/Auth"
import { MonthPicker } from "vue-month-picker"
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue"
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue"
import { VueGoodTable } from "vue-good-table"
import Calendar from "primevue/calendar"
import "primevue/resources/themes/bootstrap4-light-blue/theme.css"
import "primevue/resources/themes/saga-blue/theme.css"
import "primevue/resources/primevue.min.css"
import "primeicons/primeicons.css"
import { KmsPoup } from "@/helpers/kmsPoupDefines"
import { WEEKDAYS, FORMAT_SIGNATURES_ACCEPETD } from "@/store/constants"
import { SettingsColors } from "@/helpers/settingsColors"

const moment = extendMoment(Moment)

export default {
    name: "Kms",
    components: {
        BAvatar,
        BCard,
        BCardText,
        InfoKm,
        LastKm,
        BRow,
        BCol,
        KmTable,
        BButton,
        BBadge,
        BTabs,
        BTab,
        vueSignature,
        BFormFile,
        ToastificationContent,
        BDropdown,
        BDropdownDivider,
        BDropdownItem,
        MonthPicker,
        AppCollapse,
        AppCollapseItem,
        BFormTextarea,
        AppTimeline,
        AppTimelineItem,
        VueGoodTable,
        BFormInput,
        BFormGroup,
        Calendar,
        BFormCheckbox,
    },
    directives: {
        Ripple,
        "b-tooltip": VBTooltip,
    },
    data() {
        return {
            submited: false,
            department: "",
            hasBalance: false,
            balance: {
                opening_balance: 0,
                remaining_balance: 0,
                used_balance: 0,
            },
            balanceCalculated: {
                opening_balance: 0,
                remaining_balance: 0,
                used_balance: 0,
            },
            limit: false,
            overlap: false,
            up: true,
            resetLastKm: true,
            notKms: true,
            month: "",
            messages: [],
            message: "",
            isVisible: true,
            request: false,
            file: [],
            tabIndex: 0,
            kms: {
                condition: false,
                employee: {
                    upload_signature: "",
                },
            },
            approve: false,
            decline: false,
            reopen: false,
            nudge: false,
            changeRequest: false,
            lastMonth: {},
            entries: [],
            dateMaxMin: {
                minDate: "",
                maxDate: "",
                dateFormat: "d-m-Y",
            },
            option: {
                penColor: "rgb(0, 0, 0)",
                backgroundColor: "rgb(255,255,255)",
            },
            disabled: false,
            rerenderKmTable: true,
            action: true,
            monthPicker: false,
            signatureUrl: "",
            employee: {},
            showSignature: false,
            myValue: "",
            prevActive: true,
            nextActive: true,
            searchTerm: "",
            columns: [
                {
                    label: "Date",
                    field: "date",
                    tdClass: "height-50",
                },
                {
                    label: "Status",
                    field: "status",
                    tdClass: "height-50",
                },
                {
                    label: "Reports to",
                    field: "employee.reports_to.name",
                },
                {
                    label: "Kms",
                    field: "total_kms",
                    type: "decimal",
                    formatFn: this.formatKm,
                },
                {
                    label: "Total",
                    field: "amount",
                    type: "decimal",
                    formatFn: this.formatEuro,
                },
            ],
            allKms: [],
            listMileage: false,
            upload_signature: "",
            kmWeekend: [],
            resolvePromise: null,
            rejectPromise: null,
            kmDetail: false,
        }
    },
    watch: {
        $route(to, from) {
            ;(this.messages = []),
                (this.message = ""),
                (this.isVisible = true),
                (this.request = false),
                (this.file = ""),
                (this.tabIndex = 0),
                (this.kms = {
                    employee: {
                        upload_signature: "",
                    },
                }),
                (this.approve = false),
                (this.decline = false),
                (this.reopen = false),
                (this.nudge = false),
                (this.changeRequest = false),
                (this.lastMonth = {}),
                (this.entries = []),
                (this.dateMaxMin = {
                    minDate: "",
                    maxDate: "",
                    dateFormat: "d-m-Y",
                }),
                (this.option = {
                    penColor: "rgb(0, 0, 0)",
                    backgroundColor: "rgb(255,255,255)",
                }),
                (this.disabled = false),
                (this.rerenderKmTable = true),
                (this.action = true),
                (this.monthPicker = false),
                (this.signatureUrl = ""),
                (this.employee = {}),
                (this.showSignature = false),
                (this.myValue = ""),
                (this.prevActive = true),
                (this.nextActive = true),
                (this.columns = [
                    {
                        label: "Date",
                        field: "date",
                        tdClass: "height-50",
                    },
                    {
                        label: "Status",
                        field: "status",
                        tdClass: "height-50",
                    },
                    {
                        label: "Reports to",
                        field: "employee.reports_to.name",
                    },
                    {
                        label: "Kms",
                        field: "total_kms",
                        type: "decimal",
                        formatFn: this.formatKm,
                    },
                    {
                        label: "Amount",
                        field: "amount",
                        type: "decimal",
                        formatFn: this.formatEuro,
                    },
                ]),
                (this.allKms = [])
            this.loadData()
        },
        messages: function (value) {
            if (value.length > 0) {
                this.request = true
            } else {
                this.request = false
            }
        },
        kms: function (value) {
            if (value.date) {
                if (value.date === value.months) {
                    this.prevActive = false
                } else {
                    this.prevActive = true
                }
                if (
                    value.date.slice(0, -3) ===
                    moment(
                        new Date(
                            new Date().setMonth(new Date().getMonth() + 1)
                        ),
                        "YYYY-MM-DD"
                    )
                        .format("YYYY-MM-DD")
                        .slice(0, -3)
                ) {
                    this.nextActive = false
                } else {
                    this.nextActive = true
                }
            }
            if (
                this.$route.path !== "/forms/mileage/" &&
                this.$route.path !== "/forms/mileage"
            ) {
                var approve = true
                var decline = true
                var reopen = true
                var nudge = true
                var changeRequest = true
                if (value.length === 0) {
                    approve = false
                    decline = false
                    reopen = false
                    nudge = false
                    changeRequest = false
                }
                if (
                    value.status.description === "Open" ||
                    value.status.description === "Closed" ||
                    value.status.description === "Pending changes"
                ) {
                    approve = false
                    decline = false
                    reopen = false
                    changeRequest = false
                }
                if (value.status.description === "Approved") {
                    approve = false
                    nudge = false
                }
                if (value.status.description === "Declined") {
                    decline = false
                    nudge = false
                }
                if (
                    value.status.description === "Closed" ||
                    value.status.description === "Approved" ||
                    value.status.description === "Declined" ||
                    value.status.description === "Pending approval"
                ) {
                    nudge = false
                }
                this.approve = approve
                this.decline = decline
                this.reopen = reopen
                this.nudge = nudge
                this.changeRequest = changeRequest
            }
            Km.myKms(this.$store.state.portal.profile.employee.id).then(
                (response) => {
                    if (response !== undefined) {
                        this.allKms = response?.data?.results
                        this.$nextTick(() => {
                            this.resetLastKm = true
                        })
                    }
                }
            )
        },
        entries: {
            handler: function (after, before) {
                this.sumKms(after)
            },
            deep: true,
        },
    },
    created() {
        this.loadData()
    },
    methods: {
        async importLastMonth() {
            try {
                this.$loading(true)
                let employee_id = ""
                if (this.$route.params.id) {
                    employee_id = this.$route.params.id
                }

                const response = await Km.getKms(
                    this.kms.date.split("-")[0], //year
                    this.kms.date.split("-")[1] - 1, //month
                    employee_id
                )

                let value =
                    response.data.results.length > 0
                        ? response.data.results[0]
                        : null

                const entries = await Km.getKmEntries(value?.id)
                if (entries.data?.results.length <= 0) {
                    this.$toast(
                        ...KmsPoup.NOT_FOUND_MONTH_TO_IMPORT(
                            ToastificationContent
                        )
                    )
                    return
                }

                const km_id = this.entries[0].km_map.id
                await this.removePackageEntrys(this.entries)
                this.entries = []
                await this.addPackageEntrys(entries.data.results, km_id)
                this.$toast(
                    ...KmsPoup.SUCCESS_MONTH_IMPORT(ToastificationContent)
                )
            } catch (error) {
                console.log(error)
            } finally {
                this.$loading(false)
            }
        },
        verifyOverlap(data) {
            const total = []
            let valid = true
            this.entries.forEach((element, index) => {
                for (let i = 0; i < total.length; i++) {
                    const dt = element.date.split("-")
                    const departure = moment(
                        `${dt[2]}-${dt[1]}-${dt[0]}T${element.departure_time}`
                    )
                    const arrival = moment(
                        `${dt[2]}-${dt[1]}-${dt[0]}T${element.arrival_time}`
                    )
                    const range1 = moment.range(departure, arrival)
                    const dt2 = total[i].date.split("-")
                    const departure2 = moment(
                        `${dt2[2]}-${dt2[1]}-${dt2[0]}T${total[i].departure_time}`
                    )
                    const arrival2 = moment(
                        `${dt2[2]}-${dt2[1]}-${dt2[0]}T${total[i].arrival_time}`
                    )
                    const range2 = moment.range(departure2, arrival2)
                    if (range2.overlaps(range1)) {
                        valid = false
                        if (element.valid) {
                            element.valid = false
                        }
                    } else if (!element.valid) {
                        element.valid = true
                    }
                }
                if (valid) {
                    total.push({
                        date: element.date,
                        departure_time: element.departure_time,
                        arrival_time: element.arrival_time,
                    })
                }
            })
        },
        sumKms(value) {
            let amount = 0
            let kms = 0
            value.forEach((item) => {
                if (item?.is_real) return

                if (item?.amount) {
                    amount += parseFloat(item.amount)
                }
                if (item.kms) {
                    kms += parseFloat(item.kms)
                }
            })
            if (this.kms.amount !== amount) {
                this.kms.amount = amount
                this.kms.total_kms = kms
                this.up = false
            }
            this.$nextTick(() => {
                this.up = true
            })

            this.previusLoadBalance()
        },
        async isHoliday(date) {
            date = date.split("-")
            let result = false
            await Km.getHoliday(
                moment(
                    new Date(`${date[1]}-${date[0]}-${date[2]}`),
                    "DD-MM-YYYY"
                ).format("YYYY-MM-DD")
            ).then((response) => {
                if (response.data.results.length > 0) {
                    result = true
                }
            })
            return result
        },
        showDetail(item) {
            this.kmDetail = true
            this.showDate(new Date(item))
        },
        statusVariant(status) {
            return SettingsColors.setupVariant(status)
        },
        formatEuro(value) {
            return `${value.toFixed(2)} €`
        },
        formatKm(value) {
            return `${value.toFixed(2)} km`
        },
        deleteMessage(id) {
            this.$loading(true)
            Km.deleteMessage(id).then((response) => {
                this.getMessages(this.kms.id)
                this.$toast(...KmsPoup.DELETED_MESSAGE(ToastificationContent))
            })
        },
        getMessages(id) {
            Km.getMessage(id).then((response) => {
                this.messages = response.data.results
            })
        },
        cancelSend() {
            this.message = ""
            if (this.messages.length === 0) {
                this.request = false
            } else {
                this.$refs.tlMessages.visible = false
            }
        },
        // send () {
        //   Km.postMessage({
        //     km: this.kms.id,
        //     content: this.message,
        //   }).then(response => {
        //     this.message = ''
        //     this.getMessages(this.kms.id)
        //     Km.kmsPut({
        //       id: this.kms.id,
        //       date: this.kms.date,
        //       status: 7
        //     }).then(resp => {
        //       this.$toast({
        //         component: ToastificationContent,
        //         props: {
        //             title: 'Success',
        //             icon: 'CheckCircleIcon',
        //             text: "Message has been successfully sent! 🥳",
        //             variant: 'success',
        //           },
        //         },
        //         {
        //           position: 'top-center',
        //       })
        //       this.kms = {
        //         employee: {
        //           upload_signature: ''
        //         }
        //       }
        //       this.loadData()
        //     })
        //   }).catch(error => {
        //     this.$toast({
        //       component: ToastificationContent,
        //       props: {
        //           title: 'Error',
        //           icon: 'XIcon',
        //           text: 'Something was wrong. 😕 Please try again.',
        //           variant: 'danger',
        //         },
        //       },
        //       {
        //         position: 'bottom-center',
        //     })
        //   })
        // },
        submitRequest() {
            Km.postMessage({
                km: this.kms.id,
                content: this.message,
            })
                .then((response) => {
                    this.message = ""
                    this.getMessages(this.kms.id)
                    Km.kmsPut({
                        id: this.kms.id,
                        is_empty: this.kms?.is_empty,
                        condition: this.kms?.condition,
                        date: this.kms.date,
                        status: 7,
                    }).then((resp) => {
                        this.$toast(
                            ...KmsPoup.CHANGE_REQUEST(ToastificationContent)
                        )
                        this.kms = {
                            employee: {
                                upload_signature: "",
                            },
                        }
                        this.entries = []
                        this.loadData()
                    })
                })
                .catch((error) => {
                    this.$toast(
                        ...KmsPoup.SOMMETHING_WRONG(ToastificationContent)
                    )
                })
        },
        openRequest() {
            this.request = true
            this.$refs.tlMessages.visible = true
        },
        previousMonth() {
            this.submited = false
            let year = new Date(this.kms.date).getFullYear()
            let month = new Date(this.kms.date).getMonth()
            if (month <= 0) {
                year--
                month = 12
            }
            if (this.$route.params.id) {
                window.location.pathname = `/forms/mileage/${this.kms.employee.id}/${month}/${year}`
            } else {
                this.kms = {
                    employee: {
                        upload_signature: "",
                    },
                }
                this.entries = []
                // this.action = false;
                this.myValue = moment(
                    new Date(`${year}-${month}-1`),
                    "DD-MM-YYYY"
                ).format("DD-MM-YYYY")
                this.loadData(new Date(`${year}-${month}-1`))
            }
        },
        nextMonth() {
            this.submited = false
            let year = new Date(this.kms.date).getFullYear()
            let month = new Date(this.kms.date).getMonth() + 2
            if (month > 12) {
                year++
                month = 1
            }
            if (this.$route.params.id) {
                window.location.pathname = `/forms/mileage/${this.kms.employee.id}/${month}/${year}`
            } else {
                this.kms = {
                    employee: {
                        upload_signature: "",
                    },
                }
                this.entries = []
                // this.action = false;
                this.myValue = moment(
                    new Date(`${year}-${month}-1`),
                    "DD-MM-YYYY"
                ).format("DD-MM-YYYY")
                this.loadData(new Date(`${year}-${month}-1`))
            }
        },
        loadData(date = new Date()) {
            this.$loading(true)
            let dt = date === null || date === undefined ? new Date() : date
            let lastDay = new Date(dt.getFullYear(), dt.getMonth() + 1, 0)
            let employee_id = ""
            if (this.$route.params.id) {
                dt = new Date(
                    this.$route.params.year,
                    Number(this.$route.params.month - 1),
                    1
                )
                lastDay = new Date(
                    this.$route.params.year,
                    Number(this.$route.params.month - 1),
                    0
                )
                employee_id = this.$route.params.id
            }
            this.dateMaxMin.minDate = `${dt.getFullYear()}-${dt.getMonth()}`
            this.dateMaxMin.maxDate = `${dt.getFullYear()}-${dt.getMonth() + 1}-${lastDay.getDate()}`
            Km.getKms(dt.getFullYear(), dt.getMonth() + 1, employee_id).then(
                (response) => {
                    if (
                        this.$store?.state?.portal?.profile?.employee
                            ?.mileage ||
                        this.$route.params.id ||
                        this.kmDetail
                    ) {
                        this.$loading(true)
                        if (response.data.results.length > 0) {
                            this.kms = response.data.results[0]
                            this.month = new Date(
                                `${this.kms.date.split("-")[1]}-${
                                    this.kms.date.split("-")[2]
                                }-${this.kms.date.split("-")[0]}`
                            )
                            this.getMessages(this.kms.id)
                            if (
                                this.kms.status.description !== "Open" &&
                                this.kms.status.description !==
                                    "Pending changes"
                            ) {
                                this.action = false
                            }
                            Km.getKmEntries(this.kms.id)
                                .then((response) => {
                                    this.entries = response.data.results
                                })
                                .finally(() => this.loadBalance())
                        } else {
                            this.notKms = false
                        }
                    } else {
                        this.myValue = moment(new Date(), "DD-MM-YYYY").format(
                            "DD-MM-YYYY"
                        )
                        Km.myKms(
                            this.$store.state.portal.profile.employee.id
                        ).then((response) => {
                            this.notKms = false
                            this.kms = {
                                employee: {
                                    upload_signature: "",
                                },
                            }
                            this.allKms = response?.data?.results
                            this.except = true
                            if (response?.data?.results?.length === 0) {
                                this.$toast(
                                    ...KmsPoup.NO_RESULTS(ToastificationContent)
                                )
                            }
                        })
                    }
                }
            )
        },
        async loadBalance() {
            try {
                this.$loading(true)
                const response = await Km.getBalance({
                    date: this.kms.date,
                    employee: this.kms.employee.id,
                })
                this.balance =
                    response?.data?.results[0] !== undefined
                        ? response?.data?.results[0]
                        : this.balance
            } catch (error) {
                console.log(error.message)
            } finally {
                this.$loading(false)
                this.department =
                    this.$store.state.portal.profile.employee.department
                this.hasBalance = Boolean(this.balance?.id)
                this.previusLoadBalance()
            }
        },
        previusLoadBalance() {
            this.balanceCalculated = {
                opening_balance: "0.0",
                used_balance: "0.0",
                remaining_balance: "0.0",
            }

            if (this.kms?.status?.description === "Closed") {
                this.balanceCalculated.opening_balance =
                    this.balance.opening_balance.toString()

                this.balanceCalculated.used_balance =
                    this.balance.used_balance.toString()

                this.balanceCalculated.remaining_balance =
                    this.balance.remaining_balance.toString()
                return
            }

            if (
                this.balance?.id !== undefined ||
                this.balance?.id !== undefined
            ) {
                const totalEntriesAmountRounded = this.kms.amount

                this.balanceCalculated.opening_balance =
                    this.balance.opening_balance.toString()

                this.balanceCalculated.used_balance = (
                    this.balance.used_balance + totalEntriesAmountRounded
                ).toString()

                this.balanceCalculated.remaining_balance = (
                    this.balance.remaining_balance - totalEntriesAmountRounded
                ).toString()
            }
        },
        async deleteField() {
            try {
                let response = await Km.getKm(this.kms.id)
                this.kms = response?.data
                this.sumKms(this.entries)
            } catch (e) {
                console.error(e)
            }
        },
        nudgeAction() {
            Km.postNudge({
                id: this.kms.id,
            })
            this.$toast(...KmsPoup.NUDGE(ToastificationContent))
        },
        // changeRequestAction() {
        //   Km.postNudge({
        //     id: this.kms.id,
        //   })
        //   this.$toast(
        //     {
        //       component: ToastificationContent,
        //       props: {
        //         title: "Success",
        //         icon: "CheckCircleIcon",
        //         text: "Nudge reminder has been successfully sent! 🥳",
        //         variant: "success",
        //       },
        //     },
        //     {
        //       position: "top-center",
        //     }
        //   );
        // },
        save(id, signature = "") {
            this.$loading(true)
            const payload = {
                id: this.kms.id,
                date: this.kms.date,
                status: id,
            }
            if (signature) {
                payload.attachment = this.file
            }
            Km.updateOrCreatePeoplesKms(payload).then(() => {
                Km.getKm(this.kms.id).then((response) => {
                    this.kms = {}
                    this.kms = response?.data
                    Km.getKmEntries(this.kms.id)
                        .then((resp) => {
                            this.entries = resp?.data?.results
                            this.rerenderKmTable = false
                            this.action = true
                            this.$nextTick(() => {
                                this.rerenderKmTable = true
                            })
                            this.$loading(false)
                        })
                        .finally(() => this.loadBalance())
                })
                // this.loadData()
                switch (id) {
                    case 1:
                        this.$toast(...KmsPoup.MILEAGE(ToastificationContent))
                        break
                    case 3:
                        this.$toast(
                            ...KmsPoup.MILEAGE_SUCCESS(ToastificationContent)
                        )
                        break
                    case 4:
                        this.$toast(
                            ...KmsPoup.MILEAGE_DECLINED(ToastificationContent)
                        )
                        break
                    default:
                        break
                }
            })
        },
        showDate(value) {
            let date = ""
            date = moment(
                `1-${value.getMonth() + 1}-${value.getFullYear()}`,
                "DD-MM-YYYY"
            ).format("DD-MM-YYYY")
            if (this.myValue === "") {
                this.myValue = moment(date, "DD-MM-YYYY").format("DD-MM-YYYY")
            }
            if (this.$route.params.id) {
                window.location.pathname = `/forms/mileage/${this.$route.params.id}/${
                    value.getMonth() + 1
                }/${value.getFullYear()}`
            } else if (!this.$route.params.id) {
                this.kms = {
                    employee: {
                        upload_signature: "",
                    },
                }
                this.entries = []
                // this.action = false
                this.myValue = moment(date, "DD-MM-YYYY").format("DD-MM-YYYY")
                this.loadData(
                    new Date(
                        `${date.split("-")[1]}-${date.split("-")[0]}-${date.split("-")[2]}`
                    )
                )
                const x = document.getElementsByClassName("mp-index")
                let i
                setTimeout(() => {
                    for (i = 0; i < x.length; i++) {
                        x[i].classList.toggle("mp-index")
                    }
                    if (document.getElementById("monthDate")) {
                        document.getElementById("monthDate").click()
                    } else {
                        this.monthPicker = this.monthPicker !== true
                    }
                }, 100)
            }
        },
        async upgradeKm() {
            try {
                let response = await Km.getKm(this.kms.id)
                this.kms = response?.data
            } catch (error) {
                console.error(error)
            }
        },
        limitKm() {
            let valid = false
            this.entries.forEach((element) => {
                if (parseFloat(element.kms) > parseFloat(element.old_km) + 15) {
                    valid = true
                }
                if (parseFloat(element.kms) < parseFloat(element.old_km) - 15) {
                    valid = true
                }
            })
            return valid
        },
        handlerValidationPopup(option, info = null) {
            let inputsInvalids = ""
            if (info !== null) {
                info.map((e, i) => {
                    if (i === 0) inputsInvalids = e
                    else {
                        inputsInvalids += `, ${e}`
                    }
                })
            }

            switch (option) {
                case "infokm":
                    this.$toast(
                        ...KmsPoup.REQUIRED_FIELDS(ToastificationContent)
                    )
                    break
                case "signature":
                    this.$toast(
                        ...KmsPoup.SIGNATURE_MISSING(ToastificationContent)
                    )
                    break
                case "overlap":
                    this.$toast(
                        ...KmsPoup.OVERLAPPING_TRIPS(ToastificationContent)
                    )
                    break
                case "limit":
                    this.$toast(...KmsPoup.EXCEEDED_KMS(ToastificationContent))
                    break
                case "condition":
                    this.$toast(
                        ...KmsPoup.ACCEPT_STATEMENT(ToastificationContent)
                    )
                    break
                default:
                    this.$toast(
                        ...KmsPoup.MISSING_PARAMTER(
                            ToastificationContent,
                            inputsInvalids
                        )
                    )
            }
        },
        calculateAmoutEntries(value = null) {
            /**
             * Calculate the amout entries,
             * after fetching the information from server.
             */
            let totalEntriesAmount = 0
            this.entries.forEach((e) => {
                if (value === e.id) e.is_real = !e.is_real
                if (!e.is_real) totalEntriesAmount += Number(e?.amount)
            })
            return totalEntriesAmount
        },
        async submit() {
            this.submited = false
            if (
                this.hasBalance &&
                this.balance.remaining_balance <
                    this.calculateAmoutEntries(null)
            ) {
                this.$toast(
                    ...KmsPoup.BALANCE_REMAINING_NOT_ENOUGH(
                        ToastificationContent
                    )
                )
                return
            }
            const conditionValidation =
                document.getElementById("checkStatement")?.value

            if (this.kms?.is_empty) {
                try {
                    this.$loading(true)
                    await Km.kmsPut({
                        id: this.kms?.id,
                        is_empty: this.kms?.is_empty,
                        condition: conditionValidation,
                        date: this.kms?.date,
                        status: 5,
                    })
                    this.$toast(
                        ...KmsPoup.SUCCESS_SUBMIT(ToastificationContent)
                    )
                    this.loadData()
                } catch (error) {
                    console.log(error)
                } finally {
                    this.$loading(false)
                    this.loadBalance()
                }
            } else {
                try {
                    this.$loading(true)
                    const sure = await this.saveKms(false)
                    let valid = true
                    let type = ""

                    if (!sure) return

                    if (
                        !this.$refs.infokms.userData.employee.nif ||
                        !this.$refs.infokms.userData.employee.vehicle
                    ) {
                        valid = false
                        type = "infokm"
                    }
                    if (
                        !this.kms.attachment &&
                        !this.$store.state.portal.profile.employee.signature &&
                        !this.$store.state.portal.profile.employee
                            .upload_signature
                    ) {
                        valid = false
                        type = "signature"
                    }
                    if (this.overlap) {
                        valid = false
                        type = "overlap"
                    }
                    if (this.limitKm()) {
                        valid = false
                        type = "limit"
                    }

                    if (!conditionValidation) {
                        valid = false
                        type = "condition"
                    }

                    const inputsInvalids = []
                    this.entries.forEach((item) => {
                        if (!item.date)
                            !inputsInvalids.includes("Date") &&
                                inputsInvalids.push("Date")
                        if (!item.origin)
                            !inputsInvalids.includes("Origin") &&
                                inputsInvalids.push("Origin")
                        if (
                            !item.departure_time ||
                            item.departure_time === "00:00"
                        )
                            !inputsInvalids.includes("Departure Time") &&
                                inputsInvalids.push("Departure Time")
                        if (!item.destination)
                            !inputsInvalids.includes("Destination") &&
                                inputsInvalids.push("Destination")
                        if (!item.arrival_time || item.arrival_time === "00:00")
                            !inputsInvalids.includes("Arrival Time") &&
                                inputsInvalids.push("Arrival Time")
                        if (!item.purpose)
                            !inputsInvalids.includes("Purpose") &&
                                inputsInvalids.push("Reason")
                        if (item.departure_time === "00:00")
                            !inputsInvalids.includes("Departure Time") &&
                                inputsInvalids.push("Departure Time")

                        if (inputsInvalids.length) valid = false
                    })
                    if (!valid) {
                        this.handlerValidationPopup(type, inputsInvalids)
                        this.submited = true
                        return
                    }
                    if (valid) {
                        await Km.kmsPut({
                            id: this.kms?.id,
                            is_empty: this.kms?.is_empty,
                            condition: conditionValidation,
                            date: this.kms?.date,
                            status: 2,
                        })

                        const dt = this.kms.date.split("-")
                        let employee_id = ""
                        if (this.$route.params.id) {
                            employee_id = this.$route.params.id
                        }
                        const response = await Km.getKms(
                            dt[0],
                            dt[1],
                            employee_id
                        )
                        this.kms = response.data.results[0]
                        try {
                            const resp = await Km.getKmEntries(this.kms.id)
                            this.entries = resp.data.results
                            this.rerenderKmTable = false
                            this.action = false
                            this.$nextTick(() => {
                                this.rerenderKmTable = true
                            })
                            this.$toast(
                                ...KmsPoup.SUCCESS_SUBMIT(ToastificationContent)
                            )
                        } catch (error) {
                            console.log(error)
                        }
                    }
                } catch (error) {
                    console.log(error)
                } finally {
                    this.$loading(false)
                    this.loadBalance()
                }
            }
        },
        setStatusColor() {
            return SettingsColors.setupColor(this.kms.status.description)
        },
        signatureSave() {
            this.$loading(true)
            switch (this.tabIndex) {
                case 0:
                    // criação da assinatura eletrônica
                    this.saveSignature()
                    break
                case 1:
                    // upload da assinatura
                    if (
                        FORMAT_SIGNATURES_ACCEPETD.some((e) =>
                            this.upload_signature.name.includes(e)
                        )
                    ) {
                        const formData = new FormData()
                        formData.append(
                            "upload_signature",
                            this.upload_signature
                        )
                        formData.append(
                            "id",
                            this.$store.state.portal.profile.employee.id
                        )
                        formData.append(
                            "is_active",
                            this.$store.state.portal.profile.employee.is_active
                        )
                        formData.append(
                            "mileage",
                            this.$store?.state?.portal?.profile?.employee
                                ?.mileage
                        )
                        formData.append(
                            "expenses",
                            this.$store.state.portal.profile.employee.expenses
                        )
                        formData.append(
                            "timesheets",
                            this.$store.state.portal.profile.employee.timesheets
                        )
                        formData.append(
                            "name",
                            this.$store.state.portal.profile.employee.name
                        )
                        Auth.employeePut(
                            {
                                id: this.$store.state.portal.profile.employee
                                    .id,
                                is_active:
                                    this.$store.state.portal.profile.employee
                                        .is_active,
                                mileage:
                                    this.$store?.state?.portal?.profile
                                        ?.employee?.mileage,
                                expenses:
                                    this.$store.state.portal.profile.employee
                                        .expenses,
                                timesheets:
                                    this.$store.state.portal.profile.employee
                                        .timesheets,
                                name: this.$store.state.portal.profile.employee
                                    .name,
                            },
                            formData
                        ).then((response) => {
                            this.$store.state.portal.profile.employee.upload_signature =
                                response.data.upload_signature
                            this.$toast(
                                ...KmsPoup.SIGNATURE_SUCCESS(
                                    ToastificationContent
                                )
                            )
                        })
                    } else {
                        this.$toast(
                            ...KmsPoup.FORMAT_SUPPORTED_ERROR(
                                ToastificationContent
                            )
                        )
                        this.$loading(false)
                    }
                    break
                default:
                    // upload do file para attachment
                    this.save(this.kms.status.id, true)
                    break
            }
        },
        clear() {
            const _this = this
            _this.$refs.signature.clear()
        },
        saveSignature() {
            const formData = new FormData()
            const canvas = document.getElementById(this.$refs.signature.uid)
            canvas.toBlob((blob) => {
                formData.append("upload_signature", blob, "filename.png")
                formData.append(
                    "id",
                    this.$store.state.portal.profile.employee.id
                )
                formData.append(
                    "is_active",
                    this.$store.state.portal.profile.employee.is_active
                )
                formData.append(
                    "mileage",
                    this.$store?.state?.portal?.profile?.employee?.mileage
                )
                formData.append(
                    "expenses",
                    this.$store.state.portal.profile.employee.expenses
                )
                formData.append(
                    "timesheets",
                    this.$store.state.portal.profile.employee.timesheets
                )
                formData.append(
                    "name",
                    this.$store.state.portal.profile.employee.name
                )
                Auth.employeePut(
                    {
                        id: this.$store.state.portal.profile.employee.id,
                        is_active:
                            this.$store.state.portal.profile.employee.is_active,
                        mileage:
                            this.$store?.state?.portal?.profile?.employee
                                ?.mileage,
                        expenses:
                            this.$store.state.portal.profile.employee.expenses,
                        timesheets:
                            this.$store.state.portal.profile.employee
                                .timesheets,
                        name: this.$store.state.portal.profile.employee.name,
                    },
                    formData
                ).then((response) => {
                    this.$store.state.portal.profile.employee.upload_signature =
                        response.data.upload_signature
                    this.$toast(
                        ...KmsPoup.SIGNATURE_SUCCESS(ToastificationContent)
                    )
                })
            })
        },
        async putKms(alert, verify) {
            this.$loading(true)
            if (verify) {
                const list = []
                let item = {}
                let valid = true
                const last = this.entries.length
                let i = 0
                let send = true
                try {
                    this.entries.forEach(async (element) => {
                        i++
                        item = {}
                        if ("id" in element) {
                            item.id = element.id
                        }
                        item.sort = element.sort
                        item.km_map_id =
                            "km_map" in element ? element.km_map.id : ""
                        item.km_map =
                            "km_map" in element ? element.km_map.id : ""
                        item.date = element.date
                        item.departure_time = element.departure_time
                        item.arrival_time = element.arrival_time
                        item.origin = element.origin
                        item.destination = element.destination
                        item.purpose = element.purpose
                        item.kms = element.kms
                        item.is_real = element.is_real
                        list.push(item)
                        await Km.updateOrCreateKmEntry(item).catch(() => {
                            valid = false
                        })
                        if (last === i) {
                            if (valid && send && alert) {
                                send = false
                                await Km.kmsPut({
                                    id: this.kms?.id,
                                    is_empty: this.kms?.is_empty,
                                    condition: this.kms?.condition,
                                    date: this.kms?.date,
                                })
                                this.$toast(
                                    ...KmsPoup.SUCCESS_SUBMIT(
                                        ToastificationContent
                                    )
                                )
                            } else if (send && alert) {
                                send = false
                                this.$toast(
                                    ...KmsPoup.REQUIRED_FIELDS(
                                        ToastificationContentType
                                    )
                                )
                            }
                            if (alert) {
                                this.$loading(false)
                            } else {
                                this.$loading(true)
                            }
                        }
                    })
                    const response = await Km.getKm(this.kms.id)
                    this.kms =
                        response !== undefined ? response?.data : this.kms
                    if (alert) {
                        this.$loading(false)
                    } else {
                        this.$loading(true)
                    }
                    return true
                } catch (error) {
                    console.log(error)
                    return false
                } finally {
                    this.$loading(false)
                }
            } else {
                return false
            }
        },
        showWeekend() {
            return new Promise((resolve, reject) => {
                this.$bvModal.show("modal-weekend")
                this.resolveWeekendPromise = resolve
                this.rejectWeekendPromise = reject
            })
        },
        async saveKms(alert = true) {
            if (
                this.hasBalance &&
                this.balance.remaining_balance <
                    this.calculateAmoutEntries(null)
            ) {
                this.$toast(
                    ...KmsPoup.BALANCE_REMAINING_NOT_ENOUGH(
                        ToastificationContent
                    )
                )
                return
            }
            return new Promise(async (resolve, reject) => {
                let weekend = false
                // var date = new Date()
                this.kmWeekend = []
                const total = []
                let valid = true
                let limit = true
                this.entries.forEach(async (element) => {
                    // date = element.date.split('-')
                    // Km.getHoliday(moment(new Date(date[1] + '-' + date[0] + '-' + date[2]), "DD-MM-YYYY").format("YYYY-MM-DD")).then(response => {
                    if (
                        parseFloat(element.kms) >
                            parseFloat(element.old_km) + 15 ||
                        parseFloat(element.kms) <
                            parseFloat(element.old_km) - 15
                    ) {
                        limit = false
                    }
                    if (
                        element.day === "Saturday" ||
                        element.day === "Sunday" ||
                        element.holiday
                    ) {
                        weekend = true
                        this.kmWeekend.push(element)
                    }
                    for (let i = 0; i < total.length; i++) {
                        const dt = element.date.split("-")
                        const departure = moment(
                            `${dt[2]}-${dt[1]}-${dt[0]}T${element.departure_time}`
                        )
                        const arrival = moment(
                            `${dt[2]}-${dt[1]}-${dt[0]}T${element.arrival_time}`
                        )
                        const range1 = moment.range(departure, arrival)
                        const dt2 = total[i].date.split("-")
                        const departure2 = moment(
                            `${dt2[2]}-${dt2[1]}-${dt2[0]}T${total[i].departure_time}`
                        )
                        const arrival2 = moment(
                            `${dt2[2]}-${dt2[1]}-${dt2[0]}T${total[i].arrival_time}`
                        )
                        const range2 = moment.range(departure2, arrival2)
                        if (range2.overlaps(range1)) {
                            valid = false
                        }
                    }
                    if (valid) {
                        total.push({
                            date: element.date,
                            departure_time: element.departure_time,
                            arrival_time: element.arrival_time,
                        })
                    }
                })
                if (!valid) {
                    this.overlap = true
                } else {
                    this.overlap = false
                }
                if (limit) {
                    if (weekend && !this.$store.state.portal.kmWeekend) {
                        // this.$loading(false);
                        this.showWeekend().then(async (value) => {
                            try {
                                await this.putKms(alert, value)
                                this.$store.commit("portal/setKmWeekend", value)
                                resolve(value)
                            } catch (error) {
                                console.log(error)
                            }
                        })
                    } else {
                        try {
                            await this.putKms(alert, true)
                        } catch (error) {
                            console.log(error)
                        }
                        resolve(true)
                    }
                }
            })
        },
        async addPackageEntrys(array, km_id, init = 0) {
            try {
                this.$loading(true)
                let clone = {
                    date: moment(new Date(), "DD-MM-YYYY").format("DD-MM-YYYY"),
                    departure_time: array[init].departure_time,
                    arrival_time: array[init].arrival_time,
                    origin: array[init].origin,
                    destination: array[init].destination,
                    purpose: array[init].purpose,
                    kms: array[init].kms,
                    km_map: km_id,
                    km_map_id: km_id,
                }
                const response = await Km.updateOrCreateKmEntry(clone)
                const resp = await Km.getEntry(response.data.id)
                this.entries.push(resp.data)
            } catch (error) {
                console.log(error)
            } finally {
                if (init < array.length - 1) {
                    this.addPackageEntrys(array, km_id, init + 1)
                }
                this.$loading(false)
            }
        },
        async removePackageEntrys(array, init = 0) {
            try {
                this.$loading(true)
                await Km.rmEntry(array[init].id)
            } catch (error) {
                console.log(error)
            } finally {
                if (init < array.length - 1)
                    this.removePackageEntrys(array, init + 1)
                this.$loading(false)
            }
        },
        async add(item, today = false) {
            this.$loading(true)
            this.$refs.refKmTable.$refs.refKmTable.items.forEach((item) => {
                item._showDetails = false
            })
            let line = {}
            if (today) {
                const days = WEEKDAYS
                // console.log(item !== undefined ? item.item.km_map.id: this.kms.id);
                line = {
                    date: moment(new Date(), "DD-MM-YYYY").format("DD-MM-YYYY"),
                    day: days[new Date().getDay()],
                    km_map:
                        item.item !== undefined
                            ? item.item.km_map.id
                            : this.kms.id,
                    km_map_id:
                        item.item !== undefined
                            ? item.item.km_map.id
                            : this.kms.id,
                }
            } else {
                line = {
                    date: item.item.date,
                    day: item.item.day,
                    km_map: item.item.km_map.id,
                    km_map_id: item.item.km_map.id,
                }
            }
            try {
                const response = await Km.updateOrCreateKmEntry(line)
                const resp = await Km.getEntry(response.data.id)
                if (today) {
                    this.entries.push(resp.data)
                } else {
                    this.entries.splice(item.index + 1, 0, resp.data)
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.$loading(false)
            }
        },
        async download(exportTo = "excel") {
            try {
                this.$loading(true)
                const response =
                    exportTo === "excel"
                        ? await Km.downloadEntries(this.kms.id)
                        : await Km.downloadEntries(this.kms.id, "pdf")

                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                )
                const link = document.createElement("a")
                link.href = url
                exportTo === "excel"
                    ? link.setAttribute("download", "mileage.xlsx")
                    : link.setAttribute("download", "mileage.pdf")
                document.body.appendChild(link)
                link.click()
                return
            } catch (error) {
                this.$toast(
                    ...KmsPoup.CONTACT_MANAGEMENT(ToastificationContentType)
                )
            } finally {
                this.$loading(false)
            }
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.btn-flat-secondary-icon:hover:not(.disabled):not(:disabled) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.hidden-monthpicker {
    display: none !important;
}

.custom-month-picker {
    background: #fff;
    padding: 10px;
    border-left: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
}

[dir] .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    background: #e9ecef !important;
}

[dir]
    .p-datepicker:not(.p-disabled)
    .p-monthpicker
    .p-monthpicker-month:not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #e9ecef !important;
}

[dir] .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
    background: #e9ecef !important;
}

[dir]
    .p-datepicker:not(.p-disabled)
    .p-yearpicker
    .p-yearpicker-year:not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #e9ecef !important;
}

.p-link:focus {
    box-shadow: 0 0 0 0.2rem #e9ecef !important;
}

.p-datepicker
    .p-datepicker-header
    .p-datepicker-title
    .p-datepicker-year:enabled:hover,
.p-datepicker
    .p-datepicker-header
    .p-datepicker-title
    .p-datepicker-month:enabled:hover {
    color: #78797b !important;
}

.p-component {
    font-family: "cais-sans", sans-serif !important;
}

.p-multiselect {
    border-radius: 5px !important;
}

[dir] .p-multiselect:not(.p-disabled):hover {
    border-color: #e9ecef !important;
}

.p-inputtext:enabled:hover {
    border-color: #e9ecef !important;
}

.p-inputtext:enabled:focus {
    box-shadow: 0 0 0 0.2rem #e9ecef !important;
    border-color: #c5c8ca !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background: #e9ecef !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #78797b !important;
    background: #78797b !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #78797b !important;
}

[dir] .p-multiselect:not(.p-disabled).p-focus {
    box-shadow: none !important;
}

[dir] .p-inputtext {
    border-radius: 5px !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    box-shadow: none !important;
}
</style>
