<template>
    <div>
        <b-table
            ref="refKmTable"
            :items="entries"
            :fields="generateFields()"
            responsive
            style="margin-bottom: 150px !important!"
            :tbody-tr-class="rowClass"
            class="list-group list-group-flush"
        >
            <template #cell(show_details)="row">
                <b-form-checkbox
                    v-model="row.detailsShowing"
                    plain
                    class="vs-checkbox-con"
                    @change="
                        row.toggleDetails($event)
                        waypointsRow(row)
                    "
                >
                    <span class="vs-checkbox">
                        <span class="vs-checkbox--check">
                            <i class="vs-icon feather icon-check" />
                        </span>
                    </span>
                    <span class="vs-label">{{
                        row.detailsShowing ? "Hide" : "Show"
                    }}</span>
                </b-form-checkbox>
            </template>
            <template #row-details="row">
                <b-card>
                    <b-row>
                        <b-col
                            v-if="
                                row.item.from &&
                                row.item.to &&
                                row.item.waypoints
                            "
                            style="height: 250px"
                        >
                            <l-map
                                :zoom="maps.zoom"
                                :center="maps.center"
                                :options="maps.options"
                            >
                                <l-tile-layer
                                    :url="maps.osmUrl"
                                    :attribution="maps.attribution"
                                />
                                <l-routing-machine
                                    :waypoints="row.item.waypoints"
                                />
                            </l-map>
                        </b-col>
                    </b-row>

                    <b-button
                        size="sm"
                        class="mt-1"
                        variant="outline-secondary"
                        @click="row.toggleDetails"
                    >
                        Hide route
                    </b-button>
                </b-card>
            </template>
            <template #cell(date)="data">
                <flat-pickr
                    v-if="
                        (data.item.km_map.status.description === 'Open' ||
                            data.item.km_map.status.description ===
                                'Pending changes') &&
                        kms.is_empty === false
                    "
                    v-model="data.item.date"
                    :config="dateMaxMin"
                    :class="{
                        'form-control form-control-sm': true,
                        'danger-border': submited && data.item.date.length <= 0,
                    }"
                    placeholder="DD-MM-YYYY"
                    @on-change="dateChange($event, data)"
                />
                <span v-else>{{ data.item.date }}</span>
            </template>
            <template #cell(origin)="data">
                <vue-autosuggest
                    v-if="
                        (data.item.km_map.status.description === 'Open' ||
                            data.item.km_map.status.description ===
                                'Pending changes') &&
                        kms.is_empty === false
                    "
                    size="sm"
                    :suggestions="filteredOptions"
                    :limit="10"
                    :input-props="{
                        id: 'autosuggest__input_from_' + data.item.id,
                        class: locationClassEmpty(data.item.origin),
                        placeholder: 'From',
                    }"
                    @input="
                        data.detailsShowing === true ? '' : data.toggleDetails()
                        onInputChange($event, data)
                    "
                    v-model="data.value"
                    @selected="onSelectedFrom($event, data)"
                    @keyup.enter="setOrigin(data, filteredOptions)"
                >
                    <template slot-scope="{ suggestion }">
                        <span class="my-suggestion-item">{{
                            suggestion.item.name
                        }}</span>
                    </template>
                </vue-autosuggest>
                <span v-else>{{ data.item.origin }}</span>
            </template>
            <template #cell(destination)="data">
                <vue-autosuggest
                    v-if="
                        (data.item.km_map.status.description === 'Open' ||
                            data.item.km_map.status.description ===
                                'Pending changes') &&
                        kms.is_empty === false
                    "
                    size="sm"
                    :suggestions="filteredOptions"
                    :limit="10"
                    :input-props="{
                        id: 'autosuggest__input_to_' + data.item.id,
                        class: locationClassEmpty(data.item.destination),
                        placeholder: 'To',
                    }"
                    @input="
                        data.detailsShowing === true ? '' : data.toggleDetails()
                        onInputChange($event, data)
                    "
                    v-model="data.value"
                    @selected="onSelectedTo($event, data)"
                    @keyup.enter="setDestination(data, filteredOptions)"
                >
                    <template slot-scope="{ suggestion }">
                        <span class="my-suggestion-item">{{
                            suggestion.item.name
                        }}</span>
                    </template>
                </vue-autosuggest>
                <span v-else>{{ data.item.destination }}</span>
            </template>
            <template #cell(departure_time)="data">
                <b-form-group
                    v-if="
                        (data.item.km_map.status.description === 'Open' ||
                            data.item.km_map.status.description ===
                                'Pending changes') &&
                        kms.is_empty === false
                    "
                    style="padding-top: 13px"
                >
                    <flat-pickr
                        v-if="verifyOverlap(data)"
                        v-model="data.item.departure_time"
                        placeholder="HH:MM"
                        :class="hourClassZero(data)"
                        :config="timeConfig"
                        @on-change="changeTime($event, data)"
                    />
                    <flat-pickr
                        v-else
                        v-model="data.item.departure_time"
                        placeholder="HH:MM"
                        class="form-control form-control-sm danger-border"
                        :config="timeConfig"
                        @on-change="changeTime($event, data)"
                        v-b-tooltip.hover.v-danger="false"
                        title="Overlapping time"
                    />
                </b-form-group>
                <span v-else>{{ data.item.departure_time }}</span>
            </template>
            <template #cell(arrival_time)="data">
                <b-form-group
                    v-if="
                        (data.item.km_map.status.description === 'Open' ||
                            data.item.km_map.status.description ===
                                'Pending changes') &&
                        kms.is_empty === false
                    "
                    style="padding-top: 13px"
                >
                    <flat-pickr
                        v-if="verifyOverlap(data)"
                        v-model="data.item.arrival_time"
                        placeholder="HH:MM"
                        :class="hourClassZero(data)"
                        :config="timeConfig"
                    />
                    <flat-pickr
                        v-else
                        v-model="data.item.arrival_time"
                        placeholder="HH:MM"
                        class="form-control form-control-sm danger-border"
                        :config="timeConfig"
                        v-b-tooltip.hover.v-danger="false"
                        title="Overlapping time"
                    />
                </b-form-group>
                <span v-else>{{ data.item.arrival_time }}</span>
            </template>
            <template #cell(purpose)="data">
                <b-form-input
                    v-if="
                        (data.item.km_map.status.description === 'Open' ||
                            data.item.km_map.status.description ===
                                'Pending changes') &&
                        kms.is_empty === false
                    "
                    size="sm"
                    placeholder="Enter reason"
                    v-model="data.item.purpose"
                    :class="{
                        'danger-border':
                            submited &&
                            (data.item.purpose === null ||
                                data.item.purpose.length <= 0),
                    }"
                />
                <span v-else>{{ data.item.purpose }}</span>
            </template>
            <template #cell(kms)="data">
                <div
                    :class="
                        (data.item.km_map.status.description === 'Open' ||
                            data.item.km_map.status.description ===
                                'Pending changes') &&
                        kms.is_empty === false
                            ? 'd-flex'
                            : ''
                    "
                >
                    <div
                        v-if="
                            (data.item.km_map.status.description === 'Open' ||
                                data.item.km_map.status.description ===
                                    'Pending changes') &&
                            kms.is_empty === false
                        "
                    >
                        <b-form-input
                            v-if="!kmVerify(data)"
                            size="sm"
                            v-model="data.item.kms"
                            @input="updateAmount($event, data)"
                            style="max-width: 70px"
                            :class="{
                                'danger-border':
                                    submited &&
                                    (data.item.kms == null ||
                                        data.item.kms.length <= 0),
                            }"
                            placeholder="Kms"
                        />
                        <b-form-input
                            v-else
                            size="sm"
                            v-model="data.item.kms"
                            @input="updateAmount($event, data)"
                            style="max-width: 70px"
                            class="danger-border"
                            v-b-tooltip.hover.v-danger="false"
                            @change="returnToBackKm($event, data)"
                            :title="
                                'Exceeded value limit: [' +
                                (parseFloat(data.item.old_km) - 15) +
                                ', ' +
                                (parseFloat(data.item.old_km) + 15) +
                                ']'
                            "
                        />
                    </div>
                    <span v-else>{{ data.value }} km</span>
                    <span
                        v-if="
                            (data.item.km_map.status.description === 'Open' ||
                                data.item.km_map.status.description ===
                                    'Pending changes') &&
                            kms.is_empty === false
                        "
                        style="margin-top: 5px; margin-left: 5px"
                        >km</span
                    >
                </div>
            </template>
            <template #cell(amount)="data">
                {{ data.value | amountFilter }}
                <span>€</span>
            </template>

            <template #cell(day)="data">
                <span
                    v-if="
                        data.item.day === 'Saturday' ||
                        data.item.day === 'Sunday'
                    "
                    >{{ data.item.day }} ⛱</span
                >
                <span v-else>{{ data.item.day }}</span>
            </template>

            <template #cell(is_real)="data">
                <span>
                    <b-form-checkbox
                        :checked="data.item.is_real"
                        @input="realValue(data.item.id)"
                        switch
                        :disabled="
                            !['Open', 'Pending changes'].includes(
                                data.item.km_map.status.description
                            )
                        "
                    />
                </span>
            </template>

            <template #cell(actions)="row">
                <span class="d-flex">
                    <b-button
                        v-if="
                            (row.item.km_map.status.description === 'Open' ||
                                row.item.km_map.status.description ===
                                    'Pending changes') &&
                            kms.is_empty === false
                        "
                        v-ripple.400="'rgba(13, 110, 253, 0.15)'"
                        v-b-tooltip.hover.top="'Round trip'"
                        variant="flat-info"
                        class="btn-icon rounded-circle"
                        :disabled="
                            parseFloat(row.item.kms) >
                                parseFloat(row.item.old_km) + 15 ||
                            parseFloat(row.item.kms) <
                                parseFloat(row.item.old_km) - 15
                        "
                        @click="roundTrip(row)"
                    >
                        <feather-icon icon="RepeatIcon" />
                    </b-button>
                    <b-button
                        v-if="
                            (row.item.km_map.status.description === 'Open' ||
                                row.item.km_map.status.description ===
                                    'Pending changes') &&
                            kms.is_empty === false
                        "
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        v-b-tooltip.hover.top="'Duplicate'"
                        variant="flat-success"
                        class="btn-icon rounded-circle"
                        :disabled="
                            parseFloat(row.item.kms) >
                                parseFloat(row.item.old_km) + 15 ||
                            parseFloat(row.item.kms) <
                                parseFloat(row.item.old_km) - 15
                        "
                        @click="add(row)"
                    >
                        <feather-icon icon="CopyIcon" />
                    </b-button>
                    <b-button
                        v-if="
                            (row.item.km_map.status.description === 'Open' ||
                                row.item.km_map.status.description ===
                                    'Pending changes') &&
                            kms.is_empty === false
                        "
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        v-b-tooltip.hover.top="'Clear'"
                        variant="flat-secondary"
                        class="btn-icon rounded-circle"
                        @click="clean(row)"
                    >
                        <feather-icon icon="DeleteIcon" />
                    </b-button>
                    <b-button
                        v-if="
                            (row.item.km_map.status.description === 'Open' ||
                                row.item.km_map.status.description ===
                                    'Pending changes') &&
                            kms.is_empty === false &&
                            entries.length > 1
                        "
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        v-b-tooltip.hover.top="'Remove'"
                        variant="flat-danger"
                        class="btn-icon rounded-circle"
                        @click="del(row)"
                    >
                        <feather-icon icon="TrashIcon" />
                    </b-button>
                </span>
            </template>
        </b-table>
    </div>
</template>

<script>
import Km from "@/api/services/Km"
import TomTom from "@/api/services/TomTom"
import LRoutingMachine from "@/components/LRoutingMachine"
import { WEEKDAYS } from "@/store/constants"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import {
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCol,
    BFormCheckbox,
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTimepicker,
    BRow,
    BTable,
    VBPopover,
    VBTooltip,
} from "bootstrap-vue"
import moment from "moment"
import { VueAutosuggest } from "vue-autosuggest"
import flatPickr from "vue-flatpickr-component"
import Ripple from "vue-ripple-directive"
import { LMap, LTileLayer } from "vue2-leaflet"

const attribution = ""
const osmUrl = "http://{s}.tile.osm.org/{z}/{x}/{y}.png"

export default {
    name: "KmTable",
    components: {
        BTable,
        BButton,
        BFormCheckbox,
        BCard,
        BRow,
        BCol,
        BBadge,
        BAvatar,
        VueAutosuggest,
        LMap,
        LTileLayer,
        LRoutingMachine,
        BFormTimepicker,
        BFormGroup,
        BFormInput,
        ToastificationContent,
        flatPickr,
        BFormDatepicker,
        BFormInvalidFeedback,
    },
    directives: {
        "b-tooltip": VBTooltip,
        "b-popover": VBPopover,
        Ripple,
    },
    model: {
        prop: "value",
        event: "input",
    },
    props: {
        hasBalance: {
            type: Boolean,
            default: false,
        },
        kms: {
            type: Object,
            require: true,
        },
        value: {
            type: Array,
            require: true,
        },
        dateMaxMin: {
            type: Object,
            default: {
                minDate: "",
                maxDate: "",
            },
        },
        submited: {
            type: Boolean,
        },
        action: {
            type: Boolean,
            default: true,
        },
        department: {
            type: String,
        },
    },
    data() {
        return {
            sortableOptions: {
                chosenClass: "is-selected",
                ghostClass: "flip-list",
                onSort: (evt) => {
                    const item = {}
                },
            },
            datasuggest: [
                {
                    data: [
                        {
                            id: 1,
                            name: "Frodo",
                            race: "Hobbit",
                            avatar: "https://upload.wikimedia.org/wikipedia/en/thumb/4/4e/Elijah_Wood_as_Frodo_Baggins.png/220px-Elijah_Wood_as_Frodo_Baggins.png",
                        },
                        {
                            id: 2,
                            name: "Samwise",
                            race: "Hobbit",
                            avatar: "https://upload.wikimedia.org/wikipedia/en/thumb/7/7b/Sean_Astin_as_Samwise_Gamgee.png/200px-Sean_Astin_as_Samwise_Gamgee.png",
                        },
                        {
                            id: 3,
                            name: "Gandalf",
                            race: "Maia",
                            avatar: "https://upload.wikimedia.org/wikipedia/en/thumb/e/e9/Gandalf600ppx.jpg/220px-Gandalf600ppx.jpg",
                        },
                        {
                            id: 4,
                            name: "Aragorn",
                            race: "Human",
                            avatar: "https://upload.wikimedia.org/wikipedia/en/thumb/3/35/Aragorn300ppx.png/150px-Aragorn300ppx.png",
                        },
                    ],
                },
            ],
            filteredOptions: [],
            limit: 10,
            selected: null,
            entries: this.value,
            maps: {
                zoom: 6,
                osmUrl,
                attribution,
                waypoints: [
                    { lat: 38.7436056, lng: -9.2304153 },
                    { lat: 38.7436056, lng: -0.131281 },
                ],
                options: {
                    scrollWheelZoom: false,
                },
            },
            timeConfig: {
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
            },
            emptyLinesFrom: [],
        }
    },
    methods: {
        locationClassEmpty(data) {
            if (this.submited && (!data?.trim().length || data === null))
                return "form-control form-control-sm danger-border"
            else return "form-control form-control-sm"
        },
        hourClassZero(data) {
            if (
                this.submited &&
                ((data.item.arrival_time?.startsWith("00:00", 0) &&
                    data.item.departure_time?.startsWith("00:00", 0)) ||
                    data.item.arrival_time === null ||
                    data.item.departure_time === null)
            )
                return "form-control form-control-sm danger-border"
            else return "form-control form-control-sm"
        },
        generateFields() {
            let fields = [
                {
                    key: "show_details",
                    label: "Route",
                    class: "show-size",
                },
                {
                    key: "date",
                    class: "origin-size",
                },
                {
                    key: "day",
                },
                {
                    key: "origin",
                    class: "origin-size",
                },
                {
                    key: "departure_time",
                    label: "departure",
                    class: "departure-size",
                },
                {
                    key: "destination",
                    class: "origin-size",
                },
                {
                    key: "arrival_time",
                    label: "arrival",
                    class: "departure-size",
                },
                {
                    key: "purpose",
                    label: "reason",
                    class: "departure-size",
                },
                {
                    key: "kms",
                    class: "departure-size",
                },
                {
                    key: "amount",
                    label: "total",
                    class: "origin-size",
                },
            ]

            if (this.department !== "Rhinos") {
                fields.push({
                    key: "is_real",
                    label: "R",
                    class: "origin-size",
                })
            }

            fields.push({
                key: "actions",
                label: "actions",
                class: "origin-size",
            })

            return fields
        },
        realValue(id) {
            this.$emit("real-value", id)
        },
        kmVerify(data) {
            if (parseFloat(data.item.kms) < parseFloat(data.item.old_km) - 15) {
                return true
            }
            if (parseFloat(data.item.kms) > parseFloat(data.item.old_km) + 15) {
                return true
            }
            return false
        },
        setDestination(event, data) {
            this.onSelectedTo(
                {
                    item: data[0].data[0],
                },
                event
            )
        },
        setOrigin(event, data) {
            this.onSelectedFrom(
                {
                    item: data[0].data[0],
                },
                event
            )
        },
        verifyOverlap(data) {
            let valid = true
            for (let ix = 0; ix < this.entries.length; ix++) {
                if (data.index !== ix) {
                    const dt = this.entries[ix].date?.split("-") || false
                    const departure = moment(
                        `${dt[2]}-${dt[1]}-${dt[0]}T${this.entries[ix].departure_time}`
                    )
                    const arrival = moment(
                        `${dt[2]}-${dt[1]}-${dt[0]}T${this.entries[ix].arrival_time}`
                    )
                    const range1 = moment.range(departure, arrival)
                    const dt2 = data.item.date?.split("-") || false
                    const departure2 = moment(
                        `${dt2[2]}-${dt2[1]}-${dt2[0]}T${data.item.departure_time}`
                    )
                    const arrival2 = moment(
                        `${dt2[2]}-${dt2[1]}-${dt2[0]}T${data.item.arrival_time}`
                    )
                    const range2 = moment.range(departure2, arrival2)
                    if (range2.overlaps(range1)) {
                        valid = false
                    }
                }
            }
            return valid
        },
        changeTime(time, data) {
            if (data.item.destination) {
                // data.item.arrival_time = this.addTimes(data.item.arrival_time, this.minsToStr(this.strToMins(data.item.arrival_time) - this.strToMins(data.item.departure_time)))
                // this.onSelectedTo(data.item, data)
                this.entries.forEach((element, index) => {
                    if (data.item.id === element.id) {
                        data.detailsShowing = true
                        this.waypointsRow(data)
                        if (this.entries[index].waypoints) {
                            TomTom.getKm(
                                `${this.entries[index].waypoints[0].lat},${this.entries[index].waypoints[0].lng}`,
                                `${this.entries[index].waypoints[1].lat},${this.entries[index].waypoints[1].lng}`
                            ).then((res) => {
                                if (res?.data?.routes.length) {
                                    this.entries[index].arrival_time =
                                        this.addTimes(
                                            this.entries[index].departure_time,
                                            this.toHoursAndMinutes(
                                                res.data.routes[0].summary
                                                    .travelTimeInSeconds
                                            )
                                        )
                                    this.entries[index].kms = (
                                        res.data.routes[0].legs[0].summary
                                            .lengthInMeters / 1000
                                    ).toFixed(1)
                                }
                                this.entries[index].amount = (
                                    this.entries[index].kms * 0.4
                                ).toFixed(2)
                            })
                        }
                    }
                })
            }
        },
        toHoursAndMinutes(totalSeconds) {
            const totalMinutes = Math.floor(totalSeconds / 60)
            const seconds = totalSeconds % 60
            const hours = Math.floor(totalMinutes / 60)
            const minutes = totalMinutes % 60

            return `${hours}:${minutes}`
        },
        // Convert a time in hh:mm format to minutes
        timeToMins(time) {
            const b = time?.split(":")
            return b[0] * 60 + +b[1]
        },
        // Convert minutes to a time in format hh:mm
        // Returned value is in range 00  to 24 hrs
        timeFromMins(mins) {
            function z(n) {
                return (n < 10 ? "0" : "") + n
            }
            const h = ((mins / 60) | 0) % 24
            const m = mins % 60
            return `${z(h)}:${z(m)}`
        },
        // Add two times in hh:mm format
        addTimes(t0, t1) {
            return this.timeFromMins(this.timeToMins(t0) + this.timeToMins(t1))
        },
        strToMins(t) {
            const s = t?.split(":")
            return Number(s[0]) * 60 + Number(s[1])
        },
        minsToStr(t) {
            return `${Math.trunc(t / 60)}:${`00${t % 60}`.slice(-2)}`
        },
        returnToBackKm(event, data) {
            if (parseFloat(event) > this.entries[data.index].old_km + 15) {
                this.entries[data.index].kms = this.entries[data.index].old_km
            } else if (
                parseFloat(event) <
                this.entries[data.index].old_km - 15
            ) {
                this.entries[data.index].kms = this.entries[data.index].old_km
            }
            if (
                !isNaN(
                    data.item.km_map.value *
                        parseFloat(this.entries[data.index].kms)
                )
            ) {
                this.entries[data.index].amount =
                    data.item.km_map.value *
                    parseFloat(this.entries[data.index].kms)
            } else {
                this.entries[data.index].amount = 0.0
            }
        },
        updateAmount(event, data) {
            // if (parseFloat(event) > (this.entries[data.index].old_km + 15)){
            //   this.entries[data.index].kms = this.entries[data.index].old_km + 15
            // } else if (parseFloat(event) < (this.entries[data.index].old_km - 15)){
            //   this.entries[data.index].kms = this.entries[data.index].old_km - 15
            // }
            if (!isNaN(data.item.km_map.value * parseFloat(event))) {
                this.entries[data.index].amount =
                    data.item.km_map.value * parseFloat(event)
            } else {
                this.entries[data.index].amount = 0.0
            }
        },
        dateChange(event, data) {
            if (event[0]) {
                const days = WEEKDAYS
                this.entries[data.index].day = days[event[0].getDay()]
            }
        },
        showDelete(row) {
            let exist = 0
            this.entries.forEach((element) => {
                if (element.date === row.item.date) {
                    exist++
                }
            })
            if (exist > 1) {
                return true
            }
            return false
        },
        del(item) {
            if (item.item.id) {
                Km.rmEntry(item.item.id).then((response) => {
                    this.entries.splice(item.index, 1)
                    this.$emit("delete-field", true)
                })
            } else {
                this.entries.splice(item.index, 1)
                this.$emit("delete-field", true)
            }
        },
        clean(item) {
            item.item.departure_time = "00:00"
            item.item.arrival_time = "00:00"
            item.item.origin = ""
            item.item.destination = ""
            item.item.purpose = ""
            item.item.kms = ""
            item.item.amount = ""
        },
        async roundTrip(item) {
            try {
                this.$loading(true)
                this.$refs.refKmTable.items.forEach((item) => {
                    item._showDetails = false
                })

                if (!item.item.arrival_time && !item.item.departure_time) return

                const line = {
                    sort: item.item.sort,
                    date: item.item.date,
                    day: item.item.day,
                    origin: item.item.destination,
                    departure_time: item.item.arrival_time,
                    arrival_time: this.addTimes(
                        item.item.arrival_time,
                        this.minsToStr(
                            this.strToMins(item.item.arrival_time) -
                                this.strToMins(item.item.departure_time)
                        )
                    ),
                    purpose: item.item.purpose,
                    kms: item.item.kms,
                    old_km: item.item.old_km,
                    amount: item.item.amount,
                    destination: item.item.origin,
                    km_map: item.item.km_map.id,
                    km_map_id: item.item.km_map.id,
                }
                const response = await Km.updateOrCreateKmEntry(line)
                Km.getEntry(response.data.id).then((resp) => {
                    this.entries.splice(item.index + 1, 0, resp.data)
                })
            } catch (error) {
                console.log(error)
            } finally {
                this.$loading(false)
            }
        },
        async add(item, today = false) {
            this.$loading(true)
            this.$refs.refKmTable.items.forEach((item) => {
                item._showDetails = false
            })
            let line = {}

            const days = WEEKDAYS
            line = {
                date: today
                    ? moment(new Date(), "DD-MM-YYYY").format("DD-MM-YYYY")
                    : item.item.date,
                day: today ? days[new Date().getDay()] : item.item.day,
                origin: item.item.origin,
                departure_time: item.item.departure_time,
                destination: item.item.destination,
                arrival_time: item.item.arrival_time,
                purpose: item.item.purpose,
                kms: item.item.kms,
                amount: item.item.amount,
                km_map: item.item.km_map.id,
                km_map_id: item.item.km_map.id,
            }
            if (item.item.sort && !today) line.sort = item.item.sort

            try {
                const response = await Km.updateOrCreateKmEntry(line)
                const resp = await Km.getEntry(response.data.id)
                if (today) {
                    this.entries.push(resp.data)
                } else {
                    this.entries.splice(item.index + 1, 0, resp.data)
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.$loading(false)
            }
        },
        rowClass(item, type) {
            if (
                item.day === "Saturday" ||
                item.day === "Sunday" ||
                item.holiday
            ) {
                return "background-table-km"
            }
            return "background-table"
        },
        waypointsRow(row) {
            if (row.detailsShowing) {
                this.$loading(true)
                if (!("from" in row?.item)) {
                    TomTom.getAddress(row.item.origin).then((response) => {
                        TomTom.getAddress(row.item.destination).then((resp) => {
                            this.entries[row.index].from =
                                response?.data?.results[0]
                            this.entries[row.index].to = resp?.data?.results[0]
                            this.entries[row.index].waypoints = [
                                {
                                    lat: response?.data?.results[0].position
                                        .lat,
                                    lng: response?.data?.results[0].position
                                        .lon,
                                },
                                {
                                    lat: resp?.data?.results[0].position.lat,
                                    lng: resp?.data?.results[0].position.lon,
                                },
                            ]
                            this.$emit("input", this.entries)
                            row.toggleDetails()
                            row.toggleDetails(true)
                            this.$loading(false)
                        })
                    })
                } else if ("item" in row?.item?.from) {
                    this.entries.forEach((element, index) => {
                        if (row.item.id === element.id) {
                            this.entries[index].waypoints = [
                                {
                                    lat: row.item.from.item.position.lat,
                                    lng: row.item.from.item.position.lon,
                                },
                                {
                                    lat: row.item.to.position.lat,
                                    lng: row.item.to.position.lon,
                                },
                            ]
                            this.$emit("input", this.entries)
                            if (this.entries[index].waypoints) {
                                TomTom.getKm(
                                    `${this.entries[index].waypoints[0].lat},${this.entries[index].waypoints[0].lng}`,
                                    `${this.entries[index].waypoints[1].lat},${this.entries[index].waypoints[1].lng}`
                                ).then((res) => {
                                    this.entries[index].kms = (
                                        res.data.routes[0].legs[0].summary
                                            .lengthInMeters / 1000
                                    ).toFixed(1)
                                    this.entries[index].amount = (
                                        this.entries[index].kms * 0.4
                                    ).toFixed(2)
                                })
                            }
                        }
                    })
                    this.$loading(false)
                } else {
                    row.toggleDetails()
                    row.toggleDetails(true)
                    this.$loading(false)
                }
            }
            return true
        },
        onSelectedFrom(item, data) {
            if (item) {
                this.entries.forEach((element, index) => {
                    if (data.item.id === element.id) {
                        this.entries[index].origin =
                            item.item.address.freeformAddress
                        this.entries[index].from = item
                        this.$emit("input", this.entries)
                        if (this.entries[index].waypoints) {
                            TomTom.getKm(
                                `${this.entries[index].waypoints[0].lat},${this.entries[index].waypoints[0].lng}`,
                                `${this.entries[index].waypoints[1].lat},${this.entries[index].waypoints[1].lng}`
                            ).then((res) => {
                                this.entries[index].kms = (
                                    res.data.routes[0].legs[0].summary
                                        .lengthInMeters / 1000
                                ).toFixed(1)
                                this.entries[index].amount = (
                                    this.entries[index].kms * 0.4
                                ).toFixed(2)
                            })
                        }
                    }
                })
            }
        },
        onSelectedTo(item, data) {
            if (item) {
                this.entries.forEach((element, index) => {
                    if (data.item.id === element.id) {
                        this.entries[index].destination =
                            item.item.address.freeformAddress
                        this.entries[index].to = item.item
                        this.$emit("input", this.entries)
                        this.waypointsRow(data)
                        if (this.entries[index].waypoints) {
                            TomTom.getKm(
                                `${this.entries[index].waypoints[0].lat},${this.entries[index].waypoints[0].lng}`,
                                `${this.entries[index].waypoints[1].lat},${this.entries[index].waypoints[1].lng}`
                            ).then((res) => {
                                this.entries[index].arrival_time =
                                    this.addTimes(
                                        this.entries[index].departure_time,
                                        this.toHoursAndMinutes(
                                            res.data.routes[0].summary
                                                .travelTimeInSeconds
                                        )
                                    )
                                this.entries[index].kms = (
                                    res.data.routes[0].legs[0].summary
                                        .lengthInMeters / 1000
                                ).toFixed(1)
                                this.entries[index].old_km = (
                                    res.data.routes[0].legs[0].summary
                                        .lengthInMeters / 1000
                                ).toFixed(1)
                                this.entries[index].amount = (
                                    this.entries[index].kms * 0.4
                                ).toFixed(2)
                            })
                        }
                    }
                })
            }
        },
        onInputChange(text, row) {
            this.entries.forEach((element, index) => {
                if (element.id === row.id) {
                    this.entries[index].showDetails = true
                }
            })
            if (text === "" || text === undefined) {
                return
            }
            TomTom.getAddress(text).then((response) => {
                const items = response?.data.results.filter((element) => {
                    const item = element
                    item.name = element.address.freeformAddress
                    return item
                })

                /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
                // const filteredDevelopers = this.datasuggest[0].data.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
                // const filteredData = filteredDevelopers
                this.filteredOptions = [
                    {
                        data: items,
                    },
                ]
            })
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-autosuggest.scss";
// @import '@/assets/scss/leaflet.scss';
@import "../../node_modules/leaflet-routing-machine/dist/leaflet-routing-machine.css";

html,
body,
#app {
    height: 100%;
    margin: 0;
}

.leaflet-routing-container {
    display: none !important;
}

.show {
    z-index: 9999;
}

.show-size {
    max-width: 100px;
}

.origin-size {
    min-width: 150px;
}

.departure-size {
    min-width: 170px;
}

.background-table-km {
    background-color: #ebe9f1;
}

.background-table {
    background-color: #fafafc;
}

.flatpickr-calendar {
    width: 113.875px;
}

.flatpickr-input[readonly],
.flatpickr-input ~ .form-control[readonly],
.flatpickr-human-friendly[readonly] {
    background-color: #ffff !important;
}

.vue2leaflet-map {
    z-index: 1;
}

input[type="checkbox"] {
    accent-color: #78797b !important;
}
</style>
