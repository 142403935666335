<template>
    <b-card
        no-body
        class="card-body-show border-secondary"
        style="min-height: 23rem"
    >
        <b-card-body>
            <h4>Mileage</h4>
            <div>
                <vue-apex-charts
                    v-if="series.length > 0"
                    type="radialBar"
                    height="250"
                    class="margin-charts"
                    :options="options.chartOptions"
                    :series="series"
                />
            </div>
            <div class="d-flex">
                <div style="margin-top: 2.3rem">
                    <h4 class="font-weight-bolder mb-0 text-success">
                        {{ km.amount | currency }}
                    </h4>
                    <small class="font-small-4">Total</small>
                </div>
                <div style="position: absolute; right: 25px">
                    <b-badge variant="light-secondary" style="font-size: 14px">
                        {{ getMonthName() }}
                    </b-badge>
                </div>
            </div>
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BAvatar,
    BTabs,
    BTab,
    BCardText,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import moment from "moment"
import VueApexCharts from "vue-apexcharts"
import { $themeColors } from "@themeConfig"

export default {
    directives: {
        Ripple,
    },
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BBadge,
        BButton,
        BAvatar,
        BTabs,
        BTab,
        BCardText,
        BRow,
        BCol,
        VueApexCharts,
        BDropdown,
        BDropdownItem,
    },
    model: {
        prop: "km",
        event: "input",
    },
    props: {
        km: {
            type: Object,
            require: true,
        },
    },
    data() {
        return {
            tabs: [moment(new Date()).format("MMMM YYYY")],
            options: {
                chartOptions: {
                    plotOptions: {
                        radialBar: {
                            size: 50,
                            offsetY: 20,
                            startAngle: -150,
                            endAngle: 150,
                            hollow: {
                                size: "65%",
                            },
                            track: {
                                background: "#fff",
                                strokeWidth: "100%",
                            },
                            dataLabels: {
                                name: {
                                    offsetY: -5,
                                    color: "#5e5873",
                                    fontSize: "1rem",
                                },
                                value: {
                                    offsetY: 15,
                                    color: "#5e5873",
                                    fontSize: "1.714rem",
                                    formatter: (val) => val,
                                },
                            },
                        },
                    },
                    colors: [$themeColors.danger],
                    fill: {
                        type: "gradient",
                        gradient: {
                            shade: "dark",
                            type: "horizontal",
                            shadeIntensity: 0.5,
                            gradientToColors: [$themeColors.primary],
                            inverseColors: true,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 100],
                        },
                    },
                    stroke: {
                        dashArray: 6,
                    },
                    labels: ["km"],
                },
            },
            series: [this.km.total_kms.toFixed(2)],
        }
    },
    watch: {
        km(value) {
            this.series = []
            this.$nextTick(() => {
                this.series = [value.total_kms.toFixed(2)]
            })
        },
    },
    methods: {
        getMonthName() {
            return moment(String(this.km.date)).format("MMMM")
        },
    },
}
</script>

<style scoped>
.height-km {
    min-height: 80px;
    max-height: 80px;
}
.margin-charts {
    margin-top: -50px;
    /* margin: auto auto; */
}
.card-body-show {
    padding: 0rem;
}
</style>
