var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{ref:"refKmTable",staticClass:"list-group list-group-flush",staticStyle:{"margin-bottom":"150px !important!"},attrs:{"items":_vm.entries,"fields":_vm.generateFields(),"responsive":"","tbody-tr-class":_vm.rowClass},scopedSlots:_vm._u([{key:"cell(show_details)",fn:function(row){return [_c('b-form-checkbox',{staticClass:"vs-checkbox-con",attrs:{"plain":""},on:{"change":function($event){row.toggleDetails($event)
                    _vm.waypointsRow(row)}},model:{value:(row.detailsShowing),callback:function ($$v) {_vm.$set(row, "detailsShowing", $$v)},expression:"row.detailsShowing"}},[_c('span',{staticClass:"vs-checkbox"},[_c('span',{staticClass:"vs-checkbox--check"},[_c('i',{staticClass:"vs-icon feather icon-check"})])]),_c('span',{staticClass:"vs-label"},[_vm._v(_vm._s(row.detailsShowing ? "Hide" : "Show"))])])]}},{key:"row-details",fn:function(row){return [_c('b-card',[_c('b-row',[(
                            row.item.from &&
                            row.item.to &&
                            row.item.waypoints
                        )?_c('b-col',{staticStyle:{"height":"250px"}},[_c('l-map',{attrs:{"zoom":_vm.maps.zoom,"center":_vm.maps.center,"options":_vm.maps.options}},[_c('l-tile-layer',{attrs:{"url":_vm.maps.osmUrl,"attribution":_vm.maps.attribution}}),_c('l-routing-machine',{attrs:{"waypoints":row.item.waypoints}})],1)],1):_vm._e()],1),_c('b-button',{staticClass:"mt-1",attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":row.toggleDetails}},[_vm._v(" Hide route ")])],1)]}},{key:"cell(date)",fn:function(data){return [(
                    (data.item.km_map.status.description === 'Open' ||
                        data.item.km_map.status.description ===
                            'Pending changes') &&
                    _vm.kms.is_empty === false
                )?_c('flat-pickr',{class:{
                    'form-control form-control-sm': true,
                    'danger-border': _vm.submited && data.item.date.length <= 0,
                },attrs:{"config":_vm.dateMaxMin,"placeholder":"DD-MM-YYYY"},on:{"on-change":function($event){return _vm.dateChange($event, data)}},model:{value:(data.item.date),callback:function ($$v) {_vm.$set(data.item, "date", $$v)},expression:"data.item.date"}}):_c('span',[_vm._v(_vm._s(data.item.date))])]}},{key:"cell(origin)",fn:function(data){return [(
                    (data.item.km_map.status.description === 'Open' ||
                        data.item.km_map.status.description ===
                            'Pending changes') &&
                    _vm.kms.is_empty === false
                )?_c('vue-autosuggest',{attrs:{"size":"sm","suggestions":_vm.filteredOptions,"limit":10,"input-props":{
                    id: 'autosuggest__input_from_' + data.item.id,
                    class: _vm.locationClassEmpty(data.item.origin),
                    placeholder: 'From',
                }},on:{"input":function($event){data.detailsShowing === true ? '' : data.toggleDetails()
                    _vm.onInputChange($event, data)},"selected":function($event){return _vm.onSelectedFrom($event, data)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.setOrigin(data, _vm.filteredOptions)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.name))])]}}],null,true),model:{value:(data.value),callback:function ($$v) {_vm.$set(data, "value", $$v)},expression:"data.value"}}):_c('span',[_vm._v(_vm._s(data.item.origin))])]}},{key:"cell(destination)",fn:function(data){return [(
                    (data.item.km_map.status.description === 'Open' ||
                        data.item.km_map.status.description ===
                            'Pending changes') &&
                    _vm.kms.is_empty === false
                )?_c('vue-autosuggest',{attrs:{"size":"sm","suggestions":_vm.filteredOptions,"limit":10,"input-props":{
                    id: 'autosuggest__input_to_' + data.item.id,
                    class: _vm.locationClassEmpty(data.item.destination),
                    placeholder: 'To',
                }},on:{"input":function($event){data.detailsShowing === true ? '' : data.toggleDetails()
                    _vm.onInputChange($event, data)},"selected":function($event){return _vm.onSelectedTo($event, data)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.setDestination(data, _vm.filteredOptions)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.name))])]}}],null,true),model:{value:(data.value),callback:function ($$v) {_vm.$set(data, "value", $$v)},expression:"data.value"}}):_c('span',[_vm._v(_vm._s(data.item.destination))])]}},{key:"cell(departure_time)",fn:function(data){return [(
                    (data.item.km_map.status.description === 'Open' ||
                        data.item.km_map.status.description ===
                            'Pending changes') &&
                    _vm.kms.is_empty === false
                )?_c('b-form-group',{staticStyle:{"padding-top":"13px"}},[(_vm.verifyOverlap(data))?_c('flat-pickr',{class:_vm.hourClassZero(data),attrs:{"placeholder":"HH:MM","config":_vm.timeConfig},on:{"on-change":function($event){return _vm.changeTime($event, data)}},model:{value:(data.item.departure_time),callback:function ($$v) {_vm.$set(data.item, "departure_time", $$v)},expression:"data.item.departure_time"}}):_c('flat-pickr',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-danger",value:(false),expression:"false",modifiers:{"hover":true,"v-danger":true}}],staticClass:"form-control form-control-sm danger-border",attrs:{"placeholder":"HH:MM","config":_vm.timeConfig,"title":"Overlapping time"},on:{"on-change":function($event){return _vm.changeTime($event, data)}},model:{value:(data.item.departure_time),callback:function ($$v) {_vm.$set(data.item, "departure_time", $$v)},expression:"data.item.departure_time"}})],1):_c('span',[_vm._v(_vm._s(data.item.departure_time))])]}},{key:"cell(arrival_time)",fn:function(data){return [(
                    (data.item.km_map.status.description === 'Open' ||
                        data.item.km_map.status.description ===
                            'Pending changes') &&
                    _vm.kms.is_empty === false
                )?_c('b-form-group',{staticStyle:{"padding-top":"13px"}},[(_vm.verifyOverlap(data))?_c('flat-pickr',{class:_vm.hourClassZero(data),attrs:{"placeholder":"HH:MM","config":_vm.timeConfig},model:{value:(data.item.arrival_time),callback:function ($$v) {_vm.$set(data.item, "arrival_time", $$v)},expression:"data.item.arrival_time"}}):_c('flat-pickr',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-danger",value:(false),expression:"false",modifiers:{"hover":true,"v-danger":true}}],staticClass:"form-control form-control-sm danger-border",attrs:{"placeholder":"HH:MM","config":_vm.timeConfig,"title":"Overlapping time"},model:{value:(data.item.arrival_time),callback:function ($$v) {_vm.$set(data.item, "arrival_time", $$v)},expression:"data.item.arrival_time"}})],1):_c('span',[_vm._v(_vm._s(data.item.arrival_time))])]}},{key:"cell(purpose)",fn:function(data){return [(
                    (data.item.km_map.status.description === 'Open' ||
                        data.item.km_map.status.description ===
                            'Pending changes') &&
                    _vm.kms.is_empty === false
                )?_c('b-form-input',{class:{
                    'danger-border':
                        _vm.submited &&
                        (data.item.purpose === null ||
                            data.item.purpose.length <= 0),
                },attrs:{"size":"sm","placeholder":"Enter reason"},model:{value:(data.item.purpose),callback:function ($$v) {_vm.$set(data.item, "purpose", $$v)},expression:"data.item.purpose"}}):_c('span',[_vm._v(_vm._s(data.item.purpose))])]}},{key:"cell(kms)",fn:function(data){return [_c('div',{class:(data.item.km_map.status.description === 'Open' ||
                        data.item.km_map.status.description ===
                            'Pending changes') &&
                    _vm.kms.is_empty === false
                        ? 'd-flex'
                        : ''},[(
                        (data.item.km_map.status.description === 'Open' ||
                            data.item.km_map.status.description ===
                                'Pending changes') &&
                        _vm.kms.is_empty === false
                    )?_c('div',[(!_vm.kmVerify(data))?_c('b-form-input',{class:{
                            'danger-border':
                                _vm.submited &&
                                (data.item.kms == null ||
                                    data.item.kms.length <= 0),
                        },staticStyle:{"max-width":"70px"},attrs:{"size":"sm","placeholder":"Kms"},on:{"input":function($event){return _vm.updateAmount($event, data)}},model:{value:(data.item.kms),callback:function ($$v) {_vm.$set(data.item, "kms", $$v)},expression:"data.item.kms"}}):_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-danger",value:(false),expression:"false",modifiers:{"hover":true,"v-danger":true}}],staticClass:"danger-border",staticStyle:{"max-width":"70px"},attrs:{"size":"sm","title":'Exceeded value limit: [' +
                            (parseFloat(data.item.old_km) - 15) +
                            ', ' +
                            (parseFloat(data.item.old_km) + 15) +
                            ']'},on:{"input":function($event){return _vm.updateAmount($event, data)},"change":function($event){return _vm.returnToBackKm($event, data)}},model:{value:(data.item.kms),callback:function ($$v) {_vm.$set(data.item, "kms", $$v)},expression:"data.item.kms"}})],1):_c('span',[_vm._v(_vm._s(data.value)+" km")]),(
                        (data.item.km_map.status.description === 'Open' ||
                            data.item.km_map.status.description ===
                                'Pending changes') &&
                        _vm.kms.is_empty === false
                    )?_c('span',{staticStyle:{"margin-top":"5px","margin-left":"5px"}},[_vm._v("km")]):_vm._e()])]}},{key:"cell(amount)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("amountFilter")(data.value))+" "),_c('span',[_vm._v("€")])]}},{key:"cell(day)",fn:function(data){return [(
                    data.item.day === 'Saturday' ||
                    data.item.day === 'Sunday'
                )?_c('span',[_vm._v(_vm._s(data.item.day)+" ⛱")]):_c('span',[_vm._v(_vm._s(data.item.day))])]}},{key:"cell(is_real)",fn:function(data){return [_c('span',[_c('b-form-checkbox',{attrs:{"checked":data.item.is_real,"switch":"","disabled":!['Open', 'Pending changes'].includes(
                            data.item.km_map.status.description
                        )},on:{"input":function($event){return _vm.realValue(data.item.id)}}})],1)]}},{key:"cell(actions)",fn:function(row){return [_c('span',{staticClass:"d-flex"},[(
                        (row.item.km_map.status.description === 'Open' ||
                            row.item.km_map.status.description ===
                                'Pending changes') &&
                        _vm.kms.is_empty === false
                    )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(13, 110, 253, 0.15)'),expression:"'rgba(13, 110, 253, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Round trip'),expression:"'Round trip'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-info","disabled":parseFloat(row.item.kms) >
                            parseFloat(row.item.old_km) + 15 ||
                        parseFloat(row.item.kms) <
                            parseFloat(row.item.old_km) - 15},on:{"click":function($event){return _vm.roundTrip(row)}}},[_c('feather-icon',{attrs:{"icon":"RepeatIcon"}})],1):_vm._e(),(
                        (row.item.km_map.status.description === 'Open' ||
                            row.item.km_map.status.description ===
                                'Pending changes') &&
                        _vm.kms.is_empty === false
                    )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(40, 199, 111, 0.15)'),expression:"'rgba(40, 199, 111, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Duplicate'),expression:"'Duplicate'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-success","disabled":parseFloat(row.item.kms) >
                            parseFloat(row.item.old_km) + 15 ||
                        parseFloat(row.item.kms) <
                            parseFloat(row.item.old_km) - 15},on:{"click":function($event){return _vm.add(row)}}},[_c('feather-icon',{attrs:{"icon":"CopyIcon"}})],1):_vm._e(),(
                        (row.item.km_map.status.description === 'Open' ||
                            row.item.km_map.status.description ===
                                'Pending changes') &&
                        _vm.kms.is_empty === false
                    )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Clear'),expression:"'Clear'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-secondary"},on:{"click":function($event){return _vm.clean(row)}}},[_c('feather-icon',{attrs:{"icon":"DeleteIcon"}})],1):_vm._e(),(
                        (row.item.km_map.status.description === 'Open' ||
                            row.item.km_map.status.description ===
                                'Pending changes') &&
                        _vm.kms.is_empty === false &&
                        _vm.entries.length > 1
                    )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Remove'),expression:"'Remove'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-danger"},on:{"click":function($event){return _vm.del(row)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }