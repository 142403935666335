<template>
    <b-card>
        <b-row>
            <!-- User Info: Left col -->
            <b-col
                cols="21"
                xl="6"
                class="d-flex justify-content-between flex-column"
            >
                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start">
                    <div style="min-width: 230px">
                        <b-button
                            v-if="
                                $route.path !== '/forms/mileage' &&
                                $route.path !== '/forms/mileage/' &&
                                userData.next_previous.previous
                            "
                            v-ripple.400="'rgba(30, 30, 30, 0)'"
                            variant="flat-secondary"
                            class="btn-icon rounded-circle mb-1 mr-right-10"
                            @click="
                                $router.push(
                                    '/forms/mileage/' +
                                        userData.next_previous.previous +
                                        '/' +
                                        userData.date.split('-')[1] +
                                        '/' +
                                        userData.date.split('-')[0]
                                )
                                $router.go()
                            "
                        >
                            <feather-icon icon="ChevronLeftIcon" size="1.5x" />
                        </b-button>
                        <b-avatar
                            :src="userData.employee.avatar"
                            size="115px"
                        />
                        <b-button
                            v-if="
                                $route.path !== '/forms/mileage' &&
                                $route.path !== '/forms/mileage/' &&
                                userData.next_previous.next
                            "
                            v-ripple.400="'rgba(30, 30, 30, 0)'"
                            variant="flat-secondary"
                            class="btn-icon rounded-circle mb-1 ml-10"
                            @click="
                                $router.push(
                                    '/forms/mileage/' +
                                        userData.next_previous.next +
                                        '/' +
                                        userData.date.split('-')[1] +
                                        '/' +
                                        userData.date.split('-')[0]
                                )
                                $router.go()
                            "
                        >
                            <feather-icon icon="ChevronRightIcon" size="1.5x" />
                        </b-button>
                    </div>
                    <div class="d-flex flex-column ml-1">
                        <div class="mb-1 mt-1">
                            <h4 class="mb-0 font-weight-bolder">
                                {{ userData.employee.name }}
                            </h4>
                            <span class="card-text">{{
                                userData.employee.job_title
                            }}</span>
                        </div>
                    </div>
                </div>
                <!-- User Stats -->
            </b-col>
            <!-- Right Col: Table -->
            <b-col cols="12" xl="6" style="max-height: 14rem">
                <table class="mt-2 mt-xl-0 w-100">
                    <tr>
                        <th class="pb-50">
                            <b-avatar variant="light-secondary">
                                <feather-icon icon="MailIcon" />
                            </b-avatar>
                            <span class="margin-left-10">Email</span>
                        </th>
                        <td class="pb-50">
                            {{ userData.employee.email }}
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                            <b-avatar variant="light-info">
                                <feather-icon icon="UserIcon" />
                            </b-avatar>
                            <span class="margin-left-10">NIF</span>
                        </th>
                        <td class="pb-50 text-capitalize">
                            <span v-if="!editTrigger">{{
                                userData.employee.nif
                            }}</span>
                            <b-form-group v-if="editTrigger">
                                <b-form-input
                                    id="smallInput"
                                    size="sm"
                                    v-model="userData.employee.nif"
                                    placeholder="NIF"
                                />
                            </b-form-group>
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                            <b-avatar variant="light-warning">
                                <feather-icon icon="TruckIcon" />
                            </b-avatar>
                            <span class="margin-left-10">Vehicle</span>
                        </th>
                        <td class="pb-50 text-capitalize">
                            <span v-if="!editTrigger">{{
                                userData.employee.vehicle
                            }}</span>
                            <b-form-group v-if="editTrigger">
                                <b-form-input
                                    id="smallInput"
                                    size="sm"
                                    v-model="userData.employee.vehicle"
                                    placeholder="Vehicle"
                                />
                            </b-form-group>
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                            <b-avatar variant="light-danger">
                                <feather-icon icon="MapPinIcon" />
                            </b-avatar>
                            <span class="margin-left-10">City</span>
                        </th>
                        <td class="pb-50">
                            {{ userData.employee.city }}
                        </td>
                    </tr>
                </table>
                <div
                    v-if="editTrigger === true"
                    class="demo-inline-spacing"
                    style="float: right"
                >
                    <b-button
                        class="mt-1"
                        variant="outline-secondary"
                        @click="editTrigger = false"
                    >
                        Cancel
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(0,	0,	0, 0)'"
                        variant="primary"
                        class="mt-1"
                        @click="save()"
                    >
                        <feather-icon icon="SaveIcon" class="mr-50" />
                        <span class="align-middle">Save</span>
                    </b-button>
                </div>
                <div class="d-flex align-items-right" style="float: right">
                    <b-button
                        id="tour-km-info"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="flat-secondary"
                        class="btn-icon"
                        v-if="
                            editTrigger === false &&
                            userData.status.description === 'Open'
                        "
                        v-b-tooltip.hover.top="'Edit'"
                        v-b-modal.edit-btn
                        @click="editTrigger = true"
                        aria-pressed="false"
                    >
                        <feather-icon icon="EditIcon" size="1.3x" />
                    </b-button>
                    <span
                        style="min-height: 30px"
                        v-if="
                            editTrigger !== false ||
                            userData.status.description !== 'Open'
                        "
                    ></span>
                </div>
                <app-tour :steps="steps" />
            </b-col>
            <b-row style="padding-left: 2rem; padding-right: 2rem">
                <div class="d-flex mt-2">
                    <div class="d-flex mr-2">
                        <b-avatar variant="light-success" rounded>
                            <span style="font-size: 18px; font-weight: 100"
                                >€</span
                            >
                        </b-avatar>
                        <div class="ml-1">
                            <h5 class="mb-0 font-weight-bolder">
                                {{ userData.value }} €
                            </h5>
                            <small>Cost per km</small>
                        </div>
                    </div>
                </div>

                <div class="d-flex mt-2">
                    <div class="d-flex mr-2">
                        <b-avatar variant="light-success" rounded>
                            <span style="font-size: 18px; font-weight: 100"
                                >€</span
                            >
                        </b-avatar>
                        <div class="ml-1">
                            <h5 class="mb-0 font-weight-bolder">
                                {{
                                    balanceData !== null &&
                                    balanceData !== undefined
                                        ? balanceData.opening_balance
                                        : 0 | currency
                                }}
                            </h5>
                            <small>Total balance</small>
                        </div>
                    </div>
                </div>
                <div class="d-flex mt-2">
                    <div class="d-flex mr-2">
                        <b-avatar variant="light-success" rounded>
                            <span style="font-size: 18px; font-weight: 100"
                                >€</span
                            >
                        </b-avatar>
                        <div class="ml-1">
                            <h5 class="mb-0 font-weight-bolder">
                                {{
                                    balanceData !== null &&
                                    balanceData !== undefined
                                        ? balanceData.used_balance
                                        : 0 | currency
                                }}
                            </h5>
                            <small>Used balance</small>
                        </div>
                    </div>
                </div>
                <div class="d-flex mt-2">
                    <div class="d-flex mr-2">
                        <b-avatar variant="light-success" rounded>
                            <span style="font-size: 18px; font-weight: 100"
                                >€</span
                            >
                        </b-avatar>
                        <div class="ml-1">
                            <h5 class="mb-0 font-weight-bolder">
                                {{
                                    balanceData !== null &&
                                    balanceData !== undefined
                                        ? balanceData.remaining_balance
                                        : 0 | currency
                                }}
                            </h5>
                            <small>Remaining balance</small>
                        </div>
                    </div>
                </div>
            </b-row>
        </b-row>
    </b-card>
</template>

<script>
import {
    BCard,
    BButton,
    BAvatar,
    BRow,
    BCol,
    VBTooltip,
    BFormInput,
    BFormGroup,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import Auth from "@/api/services/Auth"
import Kms from "@/api/services/Km"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import AppTour from "@core/components/app-tour/AppTour.vue"

export default {
    components: {
        BCard,
        BButton,
        BRow,
        BCol,
        BAvatar,
        BFormInput,
        BFormGroup,
        AppTour,
    },
    directives: {
        "b-tooltip": VBTooltip,
        Ripple,
    },
    model: {
        prop: "balanceData",
        event: "input",
    },
    props: {
        userData: {
            type: Object,
            required: true,
        },
        balanceData: {
            type: Object,
        },
        hasBalance: {
            type: Boolean,
        },
    },
    data() {
        return {
            steps: [
                {
                    target: "#tour-km-info",
                    header: {
                        title: "READ ME 👇",
                    },
                    content:
                        'Make sure you have all the necessary information filled in. "NIF" and "Vehicle" are required fields.',
                    params: {
                        placement: "bottom", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                    },
                },
            ],
            editTrigger: false,
        }
    },
    mounted() {
        if (
            (!this.userData.employee.nif || !this.userData.employee.vehicle) &&
            this.$store.state.portal.profile.email ===
                this.userData.employee.email
        ) {
            this.$tours.vuexyTour.start()
        }
    },
    methods: {
        save() {
            this.$loading(true)
            Auth.employeePut({
                id: this.userData.employee.id,
                is_active: this.userData.employee.is_active,
                mileage: this.userData.employee.mileage,
                expenses: this.userData.employee.expenses,
                timesheets: this.userData.employee.timesheets,
                name: this.userData.employee.name,
                nif: this.userData.employee.nif,
                vehicle: this.userData.employee.vehicle,
            })
                .then((response) => {
                    Kms.kmsPut({
                        id: this.userData.id,
                        is_empty: this.userData.is_empty,
                        date: this.userData.date,
                        nif: this.userData.employee.nif,
                        vehicle: this.userData.employee.vehicle,
                    })
                        .then((resp) => {
                            this.editTrigger = false
                            this.$loading(false)
                            this.$toast(
                                {
                                    component: ToastificationContent,
                                    props: {
                                        title: "Success",
                                        icon: "CheckCircleIcon",
                                        text: "Your changes have been successfully saved! 🥳",
                                        variant: "success",
                                    },
                                },
                                {
                                    position: "top-center",
                                }
                            )
                        })
                        .catch(() => {
                            this.$loading(false)
                            this.$toast(
                                {
                                    component: ToastificationContent,
                                    props: {
                                        title: "Error",
                                        icon: "AlertTriangleIcon",
                                        text: "Something was wrong. 😕 Please try again.",
                                        variant: "danger",
                                    },
                                },
                                {
                                    position: "bottom-center",
                                }
                            )
                        })
                })
                .catch(() => {
                    this.$loading(false)
                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                title: "Error",
                                icon: "AlertTriangleIcon",
                                text: "Something was wrong. 😕 Please try again.",
                                variant: "danger",
                            },
                        },
                        {
                            position: "bottom-center",
                        }
                    )
                })
        },
        edit(item) {
            this.editTrigger = true
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/tour.scss";
</style>
