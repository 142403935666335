const REQUIRED_FIELDS = (component) => [
    {
        component,
        props: {
            title: "Error: Required fields are missing! 🤚",
            icon: "XIcon",
            text: "Nif and vehicle are mandatory fields.",
            variant: "danger",
        },
    },
    configPositionModal,
]

const SIGNATURE_MISSING = (component) => [
    {
        component,
        props: {
            title: "Error: Signature is missing! 🤚",
            icon: "XIcon",
            text: "Please create or upload your signature.",
            variant: "danger",
        },
    },
    configPositionModal,
]

const OVERLAPPING_TRIPS = (component) => [
    {
        component,
        props: {
            title: "Error: There are overlapping trips. 🤚",
            icon: "XIcon",
            text: "Please correct them before submitting mileage.",
            variant: "danger",
        },
    },
    configPositionModal,
]

const EXCEEDED_KMS = (component) => [
    {
        component,
        props: {
            title: "Error: Exceeded value limit. 🤚",
            icon: "XIcon",
            text: "Please try to enter up to 15 km difference.",
            variant: "danger",
        },
    },
    configPositionModal,
]

const ACCEPT_STATEMENT = (component) => [
    {
        component,
        props: {
            title: "Please make sure you select I accept statement. 🤚",
            icon: "XIcon",
            variant: "danger",
        },
    },
    configPositionModal,
]

const MISSING_PARAMTER = (component, inputsInvalids) => [
    {
        component,
        props: {
            title: "Error: Required fields are missing! 🤚",
            icon: "XIcon",
            text: `Please fill the fields ${inputsInvalids} before submitting.`,
            variant: "danger",
        },
    },
    configPositionModal,
]

const SUCCESS_SUBMIT = (component) => [
    {
        component,
        props: {
            title: "Success",
            icon: "CheckCircleIcon",
            text: "Your mileage has been successfully saved! 🥳",
            variant: "success",
        },
    },
    {
        position: "top-center",
    },
]

const CONTACT_MANAGEMENT = (component) => [
    {
        component,
        props: {
            title: "Error: Something wrong! 🤚",
            icon: "XIcon",
            text: "Please contact the your manager.",
            variant: "danger",
        },
    },
    configPositionModal,
]

const SIGNATURE_SUCCESS = (component) => [
    {
        component,
        props: {
            title: "Success",
            icon: "CheckCircleIcon",
            text: "Your signature has been successfully saved! 🥳",
            variant: "success",
        },
    },
    {
        position: "top-center",
    },
]

const FORMAT_SUPPORTED_ERROR = (component) => [
    {
        component,
        props: {
            title: "Error: File format is not supported! 🤚",
            icon: "XIcon",
            text: "You can only upload png, jpg or jpeg files.",
            variant: "danger",
        },
    },
    configPositionModal,
]

const DELETED_MESSAGE = (component) => [
    {
        component,
        props: {
            title: "Success",
            icon: "CheckCircleIcon",
            text: "Your comment has been successfully deleted! 🥳",
            variant: "success",
        },
    },
    {
        position: "top-center",
    },
]

const CHANGE_REQUEST = (component) => [
    {
        component,
        props: {
            title: "Success",
            icon: "CheckCircleIcon",
            text: "Change request has been successfully sent! 🥳",
            variant: "success",
        },
    },
    {
        position: "top-center",
    },
]

const SOMMETHING_WRONG = (component) => [
    {
        component,
        props: {
            title: "Error",
            icon: "XIcon",
            text: "Something was wrong. 😕 Please try again.",
            variant: "danger",
        },
    },
    {
        position: "bottom-center",
    },
]

const NO_RESULTS = (component) => [
    {
        component,
        props: {
            title: "Warning",
            icon: "XIcon",
            text: "Sorry, we couldn't find any results! 😕",
            variant: "warning",
        },
    },
    configTopPositionModal,
]

const NUDGE = (component) => [
    {
        component,
        props: {
            title: "Success",
            icon: "CheckCircleIcon",
            text: "Nudge reminder has been successfully sent! 🥳",
            variant: "success",
        },
    },
    {
        position: "top-center",
    },
]

const MILEAGE = (component) => [
    {
        component,
        props: {
            title: "Success",
            icon: "CheckCircleIcon",
            text: "Employee's mileage has been successfully reopened! 🥳",
            variant: "success",
        },
    },
    {
        position: "top-center",
    },
]

const MILEAGE_SUCCESS = (component) => [
    {
        component,
        props: {
            title: "Success",
            icon: "CheckCircleIcon",
            text: "Employee's mileage has been successfully approved! 🥳",
            variant: "success",
        },
    },
    {
        position: "top-center",
    },
]

const MILEAGE_DECLINED = (component) => [
    {
        component,
        props: {
            title: "Success",
            icon: "CheckCircleIcon",
            text: "Employee's mileage has been successfully declined! 🥳",
            variant: "success",
        },
    },
    {
        position: "top-center",
    },
]

const BALANCE_REMAINING_NOT_ENOUGH = (component) => [
    {
        component,
        props: {
            title: "Error",
            icon: "CheckCircleIcon",
            text: "You don't have a balance remaining to submit! 😕",
            variant: "danger",
        },
    },
    configPositionModal,
]

const configPositionModal = {
    position: "bottom-center",
    timeout: 8000,
}

const configTopPositionModal = {
    position: "top-center",
    timeout: 8000,
}

const NOT_FOUND_MONTH_TO_IMPORT = (component) => [
    {
        component,
        props: {
            title: "Error",
            icon: "CheckCircleIcon",
            text: "We couldn't find any results from last month! 😕",
            variant: "danger",
        },
    },
    configPositionModal,
]

const SUCCESS_MONTH_IMPORT = (component) => [
    {
        component,
        props: {
            title: "Success",
            icon: "CheckCircleIcon",
            text: "Past mileage has been successfully imported! 🥳",
            variant: "success",
        },
    },
    configPositionModal,
]

export const KmsPoup = {
    REQUIRED_FIELDS,
    SIGNATURE_MISSING,
    OVERLAPPING_TRIPS,
    EXCEEDED_KMS,
    ACCEPT_STATEMENT,
    MISSING_PARAMTER,
    SUCCESS_SUBMIT,
    CONTACT_MANAGEMENT,
    SIGNATURE_SUCCESS,
    FORMAT_SUPPORTED_ERROR,
    DELETED_MESSAGE,
    CHANGE_REQUEST,
    SOMMETHING_WRONG,
    NO_RESULTS,
    NUDGE,
    MILEAGE,
    MILEAGE_SUCCESS,
    MILEAGE_DECLINED,
    BALANCE_REMAINING_NOT_ENOUGH,
    NOT_FOUND_MONTH_TO_IMPORT,
    SUCCESS_MONTH_IMPORT
}
